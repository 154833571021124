import React, { ReactNode } from 'react'
import { autobind } from 'core-decorators'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  DraftModal,
  IDraft,
  IDraftModalProps,
  ICrudReport,
  IProject,
  IDraftModalState,
  IMaterial,
  IWorkStep,
  IListItem, IListProperty
} from '../../models'
import { Button, Field, Icon, Input, Modal, SearchableSelect, Spinner, Title } from '../shared'
import { List } from './list'
import './draft-modal.scss'

interface IProps extends IDraftModalProps<ICrudReport>, WrappedComponentProps {
  projects: IProject[]
  materials: IMaterial[]
  workSteps: IWorkStep[]
  isTimeRecordOpened: boolean
}

interface IState extends IDraftModalState<ICrudReport> {
  isTimeRecordTabActive: boolean
}
// tslint:disable: jsx-no-lambda
class DraftReportModal extends DraftModal<ICrudReport, IProps, IState> {
  protected initialDraft: IDraft<ICrudReport> = {
    isValid: false,
    value: {
      projectId: 0,
      date: '',
      timeRecords: [],
      materialRecords: []
    }
  }

  private get timeRecordsListProperties(): IListProperty[] {
    const { intl } = this.props

    return [
      { label: intl.formatMessage({ id: 'workStep' }), key: 'workStep', className: 'big' },
      { label: intl.formatMessage({ id: 'description' }), key: 'description', className: 'big' },
      { label: intl.formatMessage({ id: 'startTime' }), key: 'startTime' },
      { label: intl.formatMessage({ id: 'endTime' }), key: 'endTime' },
      { label: '', key: 'delete' }
    ]
  }

  private get materialRecordsListProperties(): IListProperty[] {
    const { intl } = this.props

    return [
      { label: intl.formatMessage({ id: 'material' }), key: 'material' },
      { label: intl.formatMessage({ id: 'quantity' }), key: 'quantity' },
      { label: '', key: 'delete' }
    ]
  }

  private get isEveryFieldValid(): boolean {
    const draftReport = this.state.draft.value
    const hasDate = !!draftReport.date && draftReport.date !== ''
    const hasProject = !!draftReport.projectId
    const hasRecords =  !!draftReport.timeRecords.length || !!draftReport.materialRecords.length
    const invalidInputs = document.querySelectorAll('input:invalid')

    const areTimeRecordsFulfilled = draftReport.timeRecords.length
      ? !draftReport.timeRecords.some(tr => !tr.workStep || !tr.startTime || !tr.endTime)
      : true

    const areMaterialRecordsFullfilled = draftReport.materialRecords.length
      ? draftReport.materialRecords.every(mr => !!mr.material && !!mr.quantity)
      : true

    return hasDate
      && hasProject
      && hasRecords
      && !invalidInputs.length
      && areTimeRecordsFulfilled
      && areMaterialRecordsFullfilled
  }

  private get timeRecordsListItems(): IListItem[] {
    const workSteps = this.props.workSteps
    const items = this.state.draft.value.timeRecords
    return items && items.length
      ? items.map((item, i) => {
          return ({
            workStep: (
              <SearchableSelect
                selected={ workSteps.find(x => item.workStep && x.id === item.workStep.id) }
                options={ workSteps }
                labelProp="name"
                onChange={ x => this.handleWorkStepChange(i, x) }
                placeholder="selectWorkStep"
              />
            ),
            description: (
              <Input
                placeholder="description"
                value={ item.description }
                onChange={ x => this.handleDescriptionChange(i, x) }
              />
            ),
            startTime: (
              <input
                className="input date"
                placeholder="startTime"
                value={ item.startTime }
                type="time"
                max={ item.endTime }
                onChange={ x => this.handleStartTimeChange(i, x.currentTarget.value) }
                pattern="[0-9]{2}:[0-9]{2}"
              />
            ),
            endTime: (
              <input
                className="input date"
                placeholder="endTime"
                value={ item.endTime }
                type="time"
                min={ item.startTime }
                onChange={ x => this.handleEndTimeChange(i, x.currentTarget.value) }
                pattern="[0-9]{2}:[0-9]{2}"
              />
            ),
            delete: <Icon icon="delete" onClick={ () => this.handleDeleteTimeRecord(i) } />
          })
        })
      : []
  }

  private get materialRecordsListItems(): IListItem[] {
    const items = this.state.draft.value.materialRecords
    const materials = this.props.materials
    const formattedMaterials: IMaterial[] = materials.map(material => ({
      ...material,
      name: `${ material.name } - ${ material.unit }`,
    }))

    return items && items.length
      ? items.map((item, i) => {
          return ({
            material: (
              <SearchableSelect
                selected={ formattedMaterials.find(x => item.material && x.id === item.material.id) }
                options={ formattedMaterials }
                labelProp="name"
                onChange={ x => this.handleMaterialChange(i, x) }
                placeholder="selectMaterial"
              />
            ),
            quantity: (
              <Input
                placeholder="quantity"
                type="number"
                step=".01"
                value={ item.quantity.toString() }
                onChange={ x => this.handleQtyChange(i, x) }
              />
            ),
            delete: <Icon icon="delete" onClick={ () => this.handleDeleteMaterialRecord(i) } />
          })
        })
      : []
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  constructor(props) {
    super(props)
    this.state = { draft: this.initialDraft, isTimeRecordTabActive: props.isTimeRecordOpened }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    if (
      this.props.isTimeRecordOpened !== prevState.isTimeRecordTabActive && prevProps.isHidden
    ) {
      this.setState({ isTimeRecordTabActive: this.props.isTimeRecordOpened })
    }
  }

  public render(): ReactNode {
    const { isHidden, onCancel, title, projects, isLoading, intl } = this.props
    const { draft, isTimeRecordTabActive } = this.state
    const formattedProjectNames = projects.map(p => ({ ...p, name: `${ p.name } - ${ p.customer.name }` }))

    return (
      <Modal isHidden={ isHidden } className="draft-report-modal">
        <Title label={ title } />
        <div className="fields">
          <Field label="date">
            <Input
              value={ draft.value.date || '' }
              className="date-input"
              type="date"
              onChange={ x => { this.handleChange({ date: x }) }  }
              placeholder="date"
            />
          </Field>
          <Field label="project">
            <SearchableSelect
              selected={ formattedProjectNames.find(x => draft.value.projectId && x.id === draft.value.projectId) }
              options={ formattedProjectNames }
              labelProp="name"
              onChange={ x => { this.handleChange({ projectId: x.id, projectName: x.name }) } }
              placeholder="selectProject"
            />
          </Field>
        </div>
        <div className="tabs">
          <nav>
            <section
              className={ isTimeRecordTabActive ? 'active' : '' }
              onMouseDown={ () => this.setState({ isTimeRecordTabActive: true }) }
            >
              { intl.formatMessage({ id: 'timeRecords' }) }
            </section>
            <section
              className={ isTimeRecordTabActive ? '' : 'active' }
              onMouseDown={ () => this.setState({ isTimeRecordTabActive: false }) }
            >
              { intl.formatMessage({ id: 'materialRecords' }) }
            </section>
          </nav>
          <section>
            <List
              isHidden={ !isTimeRecordTabActive }
              properties={ this.timeRecordsListProperties }
              items={ this.timeRecordsListItems }
              className="time-records"
            >
              <Icon icon="add" onClick={ this.addTimeRecord }/>
            </List>
            <List
              isHidden={ isTimeRecordTabActive }
              properties={ this.materialRecordsListProperties }
              items={ this.materialRecordsListItems }
              className="material-records"
            >
              <Icon icon="add" onClick={ this.addMaterialRecord }/>
            </List>
          </section>
        </div>
        <div className="buttons">
          <Button label={ title } onClick={ this.handleSubmit } isDisabled={ !this.isEveryFieldValid || isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading }/>
        </div>
        { this.spinner }
     </Modal>
    )
  }

  @autobind
  private addTimeRecord(): void {
    const timeRecords = this.state.draft.value.timeRecords
    timeRecords.push({
      workStep: null,
      description: '',
      startTime: '',
      endTime: ''
    })

    this.handleChange({ timeRecords })
  }

  @autobind
  private addMaterialRecord(): void {
    const materialRecords = this.state.draft.value.materialRecords
    materialRecords.push({
      material: null,
      quantity: 0
    })

    this.handleChange({ materialRecords })
  }

  @autobind
  private handleWorkStepChange(index: number, workStep: any): void {
    const timeRecords = this.state.draft.value.timeRecords
    timeRecords[index].workStep = workStep
    this.handleChange({ timeRecords })
  }

  @autobind
  private handleDescriptionChange(index: number, description: string): void {
    const timeRecords = this.state.draft.value.timeRecords
    timeRecords[index].description = description
    this.handleChange({ timeRecords })
  }

  @autobind
  private handleStartTimeChange(index: number, time: string): void {
    const timeRecords = this.state.draft.value.timeRecords
    timeRecords[index].startTime = time
    this.handleChange({ timeRecords })
  }

  @autobind
  private handleEndTimeChange(index: number, time: string): void {
    const timeRecords = this.state.draft.value.timeRecords
    timeRecords[index].endTime = time
    this.handleChange({ timeRecords })
  }

  @autobind
  private handleMaterialChange(index: number, material: any): void {
    const materialRecords = this.state.draft.value.materialRecords
    materialRecords[index].material = material
    this.handleChange({ materialRecords })
  }

  @autobind
  private handleQtyChange(index: number, quantity: string): void {
    const materialRecords = this.state.draft.value.materialRecords
    materialRecords[index].quantity = parseFloat(quantity)
    this.handleChange({ materialRecords })
  }

  @autobind
  private handleDeleteTimeRecord(index: number): void {
    const timeRecords = this.state.draft.value.timeRecords
      .filter((tr, i) => i !== index)
    this.handleChange({ timeRecords })
  }

  @autobind
  private handleDeleteMaterialRecord(index: number): void {
    const materialRecords = this.state.draft.value.materialRecords
      .filter((tr, i) => i !== index)
    this.handleChange({ materialRecords })
  }
}

export default injectIntl(DraftReportModal)
