import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Routable } from './routable'
import { routeNames } from '../navigation'
import { ISession } from './session'
import { setSessionAction } from '../store/actions'
import { SessionService } from '../services'

interface IProps extends DispatchProp<AnyAction> {
  session: ISession
}

export abstract class NonAuthenticatedRoutable<P extends IProps, S = {}> extends Routable<P, S> {
  public componentDidMount(): void {
    const activeSession = SessionService.isSessionActive()
    activeSession
      ? this.reset(`${ routeNames.reports }/${ routeNames.timeRecords }`)
      : this.props.dispatch(setSessionAction(null))
  }

  public UNSAFE_componentWillReceiveProps(nextProps: P): void {
    if (nextProps.session) this.reset(`${ routeNames.reports }/${ routeNames.timeRecords }`)
  }
}
