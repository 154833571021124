import React, { PureComponent, ReactNode, createRef } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { autobind } from 'core-decorators'
import { addDays, endOfISOWeek, startOfISOWeek, subMonths, startOfMonth, lastDayOfMonth, subWeeks } from 'date-fns/esm'
import { IRangeDate } from '../../models'
import { Icon } from './icon'
import { formatInputDate } from '../../utils'
import './range-date-picker.scss'

interface IProps extends WrappedComponentProps {
  rangeDate: IRangeDate
  onChange: (dates: IRangeDate) => void
  placeholder?: string
}

interface IState {
  isOpen: boolean
}

// tslint:disable:jsx-no-lambda

class RangeDatePicker extends PureComponent<IProps, IState> {
  private openRef = createRef<HTMLDivElement>()
  private panelRef = createRef<HTMLDivElement>()

  private get classNames(): string {
    const isOpen = this.state.isOpen
    return `range-date-picker ${ isOpen ? 'open' : '' }`
  }

  private get dates(): string {
    const { from, to } = this.props.rangeDate
    if (!from || !to) return null
    return `${ from } - ${ to }`
  }

  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false }
    document.addEventListener('click', this.handleDocumentClick)
  }

  public render(): ReactNode {
    const { intl, rangeDate } = this.props
    return (
      <div className={ this.classNames }>
        <div className="dates" ref={ this.panelRef }>
          <span>{ intl.formatMessage({ id: 'from' }) }</span>
          <input
            value={ rangeDate.from || '' }
            className="date-input"
            type="date"
            max={ rangeDate.to }
            onChange={ x => { this.handleDateChange({ from: x.currentTarget.value }) }  }
          />
          <span>{ intl.formatMessage({ id: 'to' }) }</span>
          <input
            value={ rangeDate.to || '' }
            className="date-input"
            type="date"
            min={ rangeDate.from }
            onChange={ x => { this.handleDateChange({ to: x.currentTarget.value }) }  }
          />
          <Icon icon="down" className="dropdown-button" onClick={ this.handleClick }/>
        </div>
        <ul className="date-helpers">
          <li onClick={ this.handleSelectThisWeek }>{ intl.formatMessage({ id: 'thisWeek' }) }</li>
          <li onClick={ this.handleSelectLastWeek }>{ intl.formatMessage({ id: 'lastWeek' }) }</li>
          <li onClick={ this.handleSelectThisMonth }>{ intl.formatMessage({ id: 'thisMonth' }) }</li>
          <li onClick={ this.handleSelectLastMonth }>{ intl.formatMessage({ id: 'lastMonth' }) }</li>
        </ul>
      </div>
    )
  }

  @autobind
  private handleClick(): void {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen })
  }

  @autobind
  private handleDocumentClick(event: MouseEvent): void {
    const target = event.target as ChildNode
    const openRef = this.openRef.current
    const panelRef = this.panelRef.current
    const panelChildNodes = (!!panelRef && Array.from(panelRef.childNodes)) || []
    if (
      this.state.isOpen &&
      target !== openRef &&
      target !== panelRef &&
      !panelChildNodes.includes(target)
    ) {
      this.setState({ isOpen: false })
    }
  }

  @autobind
  private handleDateChange(date: { [key: string]: string }): void {
    const { onChange, rangeDate } = this.props
    onChange({ ...rangeDate, ...date })
  }

  @autobind
  private handleSelectThisWeek(): void {
    const today = new Date()
    const weekStart = startOfISOWeek(today)
    const weekEnd =  endOfISOWeek(today)
    const correctedWeekStart = formatInputDate(addDays(weekStart, 1).toISOString())
    const correctedWeekEnd = formatInputDate(weekEnd.toISOString())
    this.props.onChange({ from: correctedWeekStart, to: correctedWeekEnd })
  }

  @autobind
  private handleSelectLastWeek(): void {
    const today = subWeeks(new Date(), 1)
    const weekStart = startOfISOWeek(today)
    const weekEnd =  endOfISOWeek(today)
    const correctedWeekStart = formatInputDate(addDays(weekStart, 1).toISOString())
    const correctedWeekEnd = formatInputDate(weekEnd.toISOString())
    this.props.onChange({ from: correctedWeekStart, to: correctedWeekEnd })
  }

  @autobind
  private handleSelectThisMonth(): void {
    const today = new Date()
    const monthStart = startOfMonth(today)
    const monthStartCorrected = formatInputDate(addDays(monthStart, 1).toISOString())
    const monthEnd = lastDayOfMonth(today)
    const monthEndCorrected = formatInputDate(addDays(monthEnd, 1).toISOString())
    this.props.onChange({ from: monthStartCorrected, to: monthEndCorrected })
  }

  @autobind
  private handleSelectLastMonth(): void {
    const today = subMonths(new Date(), 1)
    const monthStart = startOfMonth(today)
    const monthStartCorrected = formatInputDate(addDays(monthStart, 1).toISOString())
    const monthEnd = lastDayOfMonth(today)
    const monthEndCorrected = formatInputDate(addDays(monthEnd, 1).toISOString())
    this.props.onChange({ from: monthStartCorrected, to: monthEndCorrected })
  }
}

export default injectIntl(RangeDatePicker)
