import { BaseService } from './base'
import { IReportFilters, ICustomer, MaterialRecordPage, TimeRecordPage } from '../../models'
import { formatMultiParam } from '../../utils'
import { SessionService } from '..'

class ReportService extends BaseService {
  private filtersSegment = 'reports/filters'
  private segment = 'report'

  public getReportFilters(
    customers?: ICustomer[],
  ): Promise<IReportFilters> {
    return this.get(`${ this.filtersSegment }?` +
    `${ formatMultiParam(customers, 'customerIds') }`
    )
  }

  public getReportById(id: number): Promise<any> {
    return this.customFetch(`${ this.segment }/${ id }`, 'GET')
  }

  public createReport(report: any): Promise<MaterialRecordPage | TimeRecordPage> {
    return this.customFetch(this.segment, 'POST', report)
  }

  public updateReport(report: any): Promise<MaterialRecordPage | TimeRecordPage> {
    return this.customFetch(`${ this.segment }/${ report.id }`, 'PUT', report)
  }

  private async customFetch(segment: string, method: string, body?: any, token?: string): Promise<any> {
    const apiUrl = process.env.REACT_APP_TIMETRACKER_API_URL.split('/')
    apiUrl.pop()
    const truncatedApiUrl = apiUrl.join('/')

    const options = {
      method,
      headers: this.head(token),
      body: !body ? undefined : JSON.stringify(body)
    }

    try {
      const response = await fetch(`${ truncatedApiUrl }/${ segment }`, options)
      if (response.ok) return this.handleRes(response)
      else if (response.status === 401) SessionService.signOut()
      else throw new Error(response.statusText)
    } catch (error) {
      throw error
    }
  }

  private async handleRes(response: Response): Promise<any> {
    if (response.status === 204) return null
    return response.json()
  }

  private head(token = SessionService.token): Headers {
    const headers: { [key: string]: string } = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (token) headers.Authorization = `Bearer ${ token }`
    return new Headers(headers)
  }
}

export default new ReportService()
