import React, { ReactNode, PureComponent } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './field.scss'

interface IProps {
  label: string
}

class Field extends PureComponent<IProps & WrappedComponentProps> {
  public render(): ReactNode {
    const { label, intl, children } = this.props
    return (
      <div className="field">
        <span className="label">{ intl.formatMessage({ id: label }) }:</span>
        { children }
      </div>
    )
  }
}

export default injectIntl(Field)
