import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './total-time.scss'

interface IProps extends WrappedComponentProps {
  time: string
}

class TotalTime extends PureComponent<IProps> {
  public render(): ReactNode {
    if (!this.props.time) return null
    const { time, intl } = this.props
    return <span className="total-time">{ intl.formatMessage({ id: 'totalTime' }) }: <b>{ time }</b></span>
  }
}

export default injectIntl(TotalTime)
