import React, { PureComponent, ReactNode } from 'react'
import './panel.scss'

interface IProps {
  isHidden?: boolean
}

export class Panel extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isHidden, children } = this.props
    if (isHidden) return null
    return <section className="panel">{ children }</section>
  }
}
