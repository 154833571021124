import { Routable } from './routable'
import { routeNames } from '../navigation'
import { ISessionState } from '../store/states'
import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { SessionService } from '../services'
import { setSessionAction } from '../store/actions'

export interface IAuthenticatedRoutableProps extends DispatchProp<AnyAction> {
  session: ISessionState
}

export abstract class AuthenticatedRoutable<
  P extends IAuthenticatedRoutableProps = IAuthenticatedRoutableProps,
  S = {}
> extends Routable<P, S> {
  public async componentDidMount(): Promise<void> {
    const { session, dispatch } = this.props
    if (session) return

    const activeSession = await SessionService.isSessionActive()
    activeSession
      ? dispatch(setSessionAction(activeSession))
      : this.reset(routeNames.signIn)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: P): void {
    const session = nextProps.session
    if (!session) this.reset(routeNames.signIn, null, true)
  }
}
