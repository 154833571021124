import React, { PureComponent, ReactNode } from 'react'
import { literals } from '../../literals'
import './locale-selector.scss'

interface IProps {
  onClick: (locale: string) => void
  active: string
}

export class LocaleSelector extends PureComponent<IProps> {
  private get locales(): ReactNode {
    const { active, onClick } = this.props
    return Object.keys(literals).map(x => {
      const className = `locale ${ active === x ? 'active' : '' }`
      return <li className={ className } key={ x } onClick={ onClick.bind(this, x) }>{ x.toUpperCase() }</li>
    })
  }
  public render(): ReactNode {
    return <ul className="locale-selector">{ this.locales }</ul>
  }
}
