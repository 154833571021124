import React, { ReactNode, PureComponent } from 'react'
import { Modal, Title, Button, Text } from '../shared'
import './password-reset-modal.scss'

interface IProps {
  onClose: () => void
  isHidden: boolean
}

export class PasswordResetModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onClose, isHidden } = this.props
    return (
      <Modal isHidden={ isHidden } className="password-reset-modal">
        <Title label="passwordReset" />
        <Text label="passwordResetSuccessfully" />
        <Button label="close" onClick={ onClose } />
      </Modal>
    )
  }
}
