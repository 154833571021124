import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { ITabOption } from '../../models'
import './tabs.scss'

interface IProps extends WrappedComponentProps {
  options: ITabOption[]
  active: ITabOption
  onChange: (option: ITabOption) => void
}

class Tabs extends PureComponent<IProps> {
  private get options(): ReactNode {
    const { options, intl, active, onChange } = this.props
    return options.map(x => (
      <li
        key={ x.label }
        className={ `option ${ active.label === x.label ? 'active' : '' }` }
        onClick={ onChange.bind(this, x) }
      >{ intl.formatMessage({ id: x.label }) }</li>
    ))
  }

  private get content(): ReactNode {
    const { options, active } = this.props
    return options.find(x => x.label === active.label).content
  }

  public render(): ReactNode {
    if (!this.props.active) return null
    return (
      <article className="tabs">
        <ul className="options">{ this.options }</ul>
        <section className="content">{ this.content }</section>
      </article>
    )
  }
}

export default injectIntl(Tabs)
