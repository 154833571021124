import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { autobind } from 'core-decorators'
import { ISessionState, IStoreState, IProjectsState } from '../../store/states'
import { ITableProperty, IProject, CrudPage, ICustomer, ICrudPageState, emptyPage } from '../../models'
import { Menu } from '../../containers'
import {
  Button,
  Panel,
  Table,
  Field,
  Toggle,
  Pagination,
  TableActions,
  DraftProjectModal,
  PageHeader,
  Input,
  DeleteConfirmationModal
} from '../../components'
import { ProjectService, CustomerService } from '../../services'
import { setProjectsAction } from '../../store/actions'

interface IProps {
  session: ISessionState
  page: IProjectsState
}

interface IState extends ICrudPageState<IProject> {
  customers: ICustomer[]
}

type Props = IProps & DispatchProp<AnyAction>

// tslint:disable:jsx-no-lambda

class ProjectsPage extends CrudPage<IProject, Props, IState> {
  private tableProperties: ITableProperty[] = [
    { key: 'name', label: 'name' },
    { key: 'customer', label: 'customerName' },
    { key: 'operation', label: 'operation', className: 'no-flex' }
  ]

  private get tableItems(): Array<{}> {
    const items = this.props.page.items
    return items && items.map(x => ({
      name: x.name,
      customer: x.customer.name,
      operation:
        <TableActions onDelete={ () => this.handleOpenDelete(x) } onEdit={ () => this.handleOpenEdit(x) } />
    }))
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.initialState,
      customers: []
    }
  }

  public async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.fetchCustomers()
  }

  public componentWillUnmount(): void {
    super.componentWillUnmount()
    this.props.dispatch(setProjectsAction(emptyPage))
  }

  public render(): ReactNode {
    const {
      areArchivedIncluded,
      isEditing,
      isSendingRequest,
      editingItem,
      isCreating,
      customers,
      filters,
      deletingItem
    } = this.state
    const { page, session } = this.props
    return (
      <section className="page fully-aligned work-steps">
        <Menu />
        <article className="content">
          <PageHeader session={ session } label="projects" />
          <div className="filters">
            <Button label="new" className="new-button" onClick={ this.handleOpenCreate } />
            <Input
              value={ filters.query.value }
              placeholder="search"
              isInvalid={ !!filters.query.value && !filters.query.isValid }
              onChange={ x => this.handleFilterChange({ query: { value: x, isValid: x.length >= 3 } }) }
            />
          </div>
          <Panel>
            <Table properties={ this.tableProperties } items={ this.tableItems } />
          </Panel>
          <footer>
            <Field label="includeArchieved" >
              <Toggle
                isActive={ areArchivedIncluded }
                onChange={ this.handleArchivedIncludedChange }
              />
            </Field>
            <Pagination
              onClick={ this.fetchItems }
              isHidden={ !page.pagination }
              pagination={ page.pagination }
            />
          </footer>
        </article>
        <DraftProjectModal
          title={ isCreating ? 'create' : 'save' }
          isHidden={ !isCreating && !isEditing }
          onSubmit={ (isCreating && this.handleCreate) || (isEditing && this.handleEdit) }
          onCancel={ (isCreating && this.handleCancelCreate) || (isEditing && this.handleCancelEdit) }
          isLoading={ isSendingRequest }
          item={ editingItem }
          customers={ customers }
        />
        <DeleteConfirmationModal
          isHidden={ !deletingItem }
          onCancel={ () => this.handleOpenDelete(null) }
          onConfirm={ this.handleDelete }
          isLoading={ isSendingRequest }
        />
      </section>
    )
  }

  @autobind
  protected async handleCreate(project: IProject): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await ProjectService.createProject(project)
      await this.fetchItems()
      this.setState({ isCreating: false })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleEdit(project: IProject): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await ProjectService.updateProjects(project)
      await this.fetchItems()
      this.setState({ isEditing: false, editingItem: null })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleDelete(): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await ProjectService.deleteProjects(this.state.deletingItem.id)
      this.setState({ deletingItem: null })
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async fetchItems(page = 1, areArchivedIncluded = false): Promise<void> {
    try {
      const query = this.state.filters.query
      const result = await ProjectService.getProjects(page, areArchivedIncluded, query)
      this.props.dispatch(setProjectsAction(result))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  private async fetchCustomers(): Promise<void> {
    try {
      const page = await CustomerService.getCustomers()
      this.setState({ customers: page.items })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }
}

const mapStateToProps = ({ session, projects }: IStoreState): IProps => ({
  session,
  page: projects
})

export default connect(mapStateToProps)(ProjectsPage)
