import React, { PureComponent, ReactNode, SyntheticEvent } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { literals } from '../../literals'
import './button.scss'

interface IProps extends WrappedComponentProps {
  label: string
  onClick?: (event: SyntheticEvent) => void
  isDisabled?: boolean
  className?: string
}

class Button extends PureComponent<IProps> {
  private get className(): string {
    const classNames = this.props.className
    return `button ${ classNames ? classNames : '' }`
  }

  private get label(): string {
    const { label, intl } = this.props
    return Reflect.has(literals.en, label)
      ? intl.formatMessage({ id: label })
      : label
  }

  public render(): ReactNode {
    const { onClick, isDisabled } = this.props
    return (
      <button
        className={ this.className }
        onClick={ onClick }
        disabled={ isDisabled }
        type="submit"
      >{ this.label }</button>
    )
  }
}

export default injectIntl(Button)
