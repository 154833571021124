import React, { ReactNode, PureComponent, createRef } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { autobind } from 'core-decorators'
import { Icon } from './icon'
import './searchable-multi-select.scss'

interface IProps extends WrappedComponentProps {
  labelProp: string
  icon: string
  selected: any[]
  options: any[]
  onChange: (selected: any[]) => void
  placeholder?: string
}

interface IState {
  isOpen: boolean
  optionFilter: string
}

class SearchableMultiSelect extends PureComponent<IProps, IState> {
  private openRef = createRef<HTMLDivElement>()
  private inputRef = createRef<HTMLInputElement>()

  private get classNames(): string {
    const options = this.props.options
    const isOpen = this.state.isOpen
    const isDisabled = !options || !options.length
    return `searchable-multi-select ${ isOpen ? 'open' : '' } ${ isDisabled ? 'disabled' : '' }`
  }

  private get selectedLabel(): string {
    const { selected, placeholder, intl } = this.props
    if (selected.length === 1) return selected[0][this.props.labelProp]
    else if (selected.length > 1) return intl.formatMessage({ id: 'multipleValuesSelected' })
    return intl.formatMessage({ id: placeholder })
  }

  private get options(): ReactNode {
    const { options, selected, labelProp } = this.props
    const { optionFilter } = this.state
    const filteredOptions = !!optionFilter
      ? options.filter(option => {
          return !!labelProp
            ? option[labelProp].toLowerCase().includes(optionFilter.toLowerCase())
            : option.includes(optionFilter)
        })
      : [...options]
    return filteredOptions.map((x, i) => {
      const isSelected = !!selected.find(y => y.id === x.id)
      return (
        <li
          className={ `option ${ isSelected ? 'selected' : '' }` }
          key={ i }
          onClick={ this.handleOptionClick.bind(this, x) }
        >
          <input
            className="checkbox"
            type="checkbox"
            checked={ isSelected }
            onChange={ this.handleCheckboxChange }
          />
          <label className="label">{ x[labelProp] }</label>
        </li>
      )
    })
  }

  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false, optionFilter: '' }
    document.addEventListener('click', this.handleDocumentClick)
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!prevState.isOpen && this.state.isOpen && this.inputRef) this.inputRef.current.focus()
  }

  public render(): ReactNode {
    const { icon, selected, intl } = this.props
    return (
      <article className={ this.classNames }>
        <div
          className="selected"
          ref={ this.openRef }
          onClick={ this.handleClick }
        >
          <Icon icon={ icon } />
          <span className="label">{ this.selectedLabel }</span>
          <span className="amount">{ selected.length }</span>
        </div>
        <div className="options-container">
          <input
            ref={ this.inputRef }
            className="search"
            type="text"
            placeholder={ intl.formatMessage({ id: 'search' }) }
            // tslint:disable-next-line: jsx-no-lambda
            onChange={ x => this.handleOptionSearch(x.currentTarget.value) }
          />
          <ul className="options">{ this.options }</ul>
        </div>
      </article>
    )
  }

  @autobind
  private handleClick(): void {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen })
  }

  @autobind
  private handleOptionClick(option: any): void {
    const { onChange, selected } = this.props
    !!selected.find(x => x.id === option.id)
     ? onChange(selected.filter(x => x.id !== option.id))
     : onChange([ ...selected, option ])
  }

  @autobind
  private handleDocumentClick(event: MouseEvent): void {
    const openRef = this.openRef.current
    const inputRef = this.inputRef.current
    if (this.state.isOpen && !!openRef && !!inputRef && event.target !== openRef && event.target !== inputRef) {
      this.setState({ isOpen: false })
    }
  }

  private handleCheckboxChange(): void {
    // fake controlled React component
  }

  @autobind
  private handleOptionSearch(value: string): void {
    this.setState({ optionFilter: value })
  }
}

export default injectIntl(SearchableMultiSelect)
