import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { autobind } from 'core-decorators'
import './toggle.scss'

interface IProps extends WrappedComponentProps {
  isActive: boolean
  onChange: (value: boolean) => void
}

class Toggle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isActive } = this.props
    return <div className={ `toggle ${ isActive ? 'active' : '' }` } onClick={ this.handleClick } />
  }

  @autobind
  private handleClick(): void {
    const { onChange, isActive } = this.props
    onChange(!isActive)
  }
}

export default injectIntl(Toggle)
