import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './tabs.scss'

interface ITabOption {
  label: string,
  onClick: () => void
}

interface IProps extends WrappedComponentProps {
  options: ITabOption[]
  active: ITabOption
  children: ReactNode
}

class Tabs2 extends PureComponent<IProps> {
  private get options(): ReactNode {
    const { options, intl, active } = this.props
    return options.map(x => (
      <li
        key={ x.label }
        className={ `option ${ active.label === x.label ? 'active' : '' }` }
        onClick={ x.onClick }
      >{ intl.formatMessage({ id: x.label }) }</li>
    ))
  }

  public render(): ReactNode {
    const { active, children } = this.props
    if (!active) return null
    return (
      <article className="tabs">
        <ul className="options">{ this.options }</ul>
        <section className="content">{ children }</section>
      </article>
    )
  }
}

export default injectIntl(Tabs2)
