import React, { ReactNode } from 'react'
import { Modal, Title, Button, Field, Input, Spinner } from '../shared'
import { IDraft, IWorkStep, DraftModal, IDraftModalProps } from '../../models'

// tslint:disable:jsx-no-lambda

export class DraftWorkStepModal extends DraftModal<IWorkStep, IDraftModalProps<IWorkStep>> {
  protected initialDraft: IDraft<IWorkStep> = {
    isValid: false,
    value: { name: '' }
  }

  constructor(props) {
    super(props)
    this.state = { draft: this.initialDraft }
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  public render(): ReactNode {
    const { isHidden, onCancel, title, isLoading } = this.props
    const draft = this.state.draft
    return (
      <Modal isHidden={ isHidden } className="draft-work-step-modal">
        <Title label={ title } />
        <div className="fields">
          <Field label="name">
            <Input
              placeholder="name"
              value={ draft.value.name }
              onChange={ x => { this.handleChange({ name: x }) } }
            />
          </Field>
        </div>
        <div className="buttons">
          <Button label={ title } onClick={ this.handleSubmit } isDisabled={ isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading } />
        </div>
        { this.spinner }
      </Modal>
    )
  }
}
