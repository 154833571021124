import { BaseService } from './base'
import { IWorker, WorkerPage, IValidatedInputField } from '../../models'
import { formatParam } from '../../utils'

class WorkerService extends BaseService {
  private segment = 'workers'

  public getWorkers(
    page = 1,
    areArchivedIncluded = false,
    query?: IValidatedInputField<string>,
    size = 20
  ): Promise<WorkerPage> {
    return this.get(
      `${ this.segment }?page=${ page }&size=${ size }&archived=${ areArchivedIncluded }` +
      `${ formatParam(query.value, 'q', query.isValid) }`
    )
  }

  public deleteWorker(id: number): Promise<void> {
    return this.delete(`${ this.segment }/${ id }`)
  }

  public createWorker(worker: IWorker): Promise<IWorker> {
    const workerBody = {...worker, password: worker.newPassword}
    return this.post(this.segment, workerBody)
  }

  public updateWorkerPassword(worker: IWorker): Promise<IWorker> {
    const updatePasswordBody = {
      newPassword: worker.newPassword,
      confirmNewPassword: worker.confirmNewPassword,
    }
    return this.patch(`${ this.segment }/${ worker.id }/password`, updatePasswordBody)
  }

  public updateWorker(worker: IWorker): Promise<IWorker> {
    return this.put(`${ this.segment }/${ worker.id }`, worker)
  }
}

export default new WorkerService()
