import * as React from 'react'
import { configurePageRenderer } from '../utils'
import { MaterialRecordsPage, TimeRecordsPage } from '../pages'

export const reportsRouteNames = {
  reports: `reports`,
  timeRecords: `time`,
  materialRecords: `material`
}

export const reportsPages = {
  [reportsRouteNames.materialRecords]: configurePageRenderer(<MaterialRecordsPage />),
  [reportsRouteNames.timeRecords]: configurePageRenderer(<TimeRecordsPage />)
}

export const reportsRoutes = {
  [`/${ reportsRouteNames.reports }`]: {
    [`/${ reportsRouteNames.materialRecords }`]: reportsRouteNames.materialRecords,
    [`/${ reportsRouteNames.timeRecords }`]: reportsRouteNames.timeRecords
  }
}
