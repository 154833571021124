import * as React from 'react'
import { configurePageRenderer } from '../utils'
import { WorkspacesPage } from '../pages'

export const administrationRouteNames = {
  workspaces: `workspaces`,
}

export const administrationPages = {
  [administrationRouteNames.workspaces]: configurePageRenderer(<WorkspacesPage />)
}

export const administrationRoutes = {
  [`/${ administrationRouteNames.workspaces }`]: administrationRouteNames.workspaces
}
