import React, { PureComponent, ReactNode } from 'react'
import Button from './button'
import { IPagination } from '../../models/pagination'
import './pagination.scss'

interface IProps {
  isHidden: boolean
  pagination: IPagination
  onClick: (page: number) => void
}

export class Pagination extends PureComponent<IProps> {
  private get range(): number {
    const { pageNumber, totalPages } = this.props.pagination
    if (pageNumber === totalPages) return 0
    else if (pageNumber > 1 || totalPages === 2) return 1
    else return 2
  }

  private get startPage(): number {
    const { pageNumber, totalPages } = this.props.pagination
    if (pageNumber === totalPages && pageNumber > 2) return pageNumber - 2
    else if (pageNumber > 1) return pageNumber - 1
    else return pageNumber
  }

  private get pages(): ReactNode {
    const { pagination, onClick } = this.props
    const pages: ReactNode[] = []
    const pageNumber = pagination.pageNumber
    let i = this.startPage

    while (i <= pageNumber + this.range) {
      pages.push(
        <Button
          className={ i === pageNumber && 'active' }
          label={ i.toString() }
          onClick={ onClick.bind(this, i) }
          key={ i }
        />
      )
      i++
    }

    return pages
  }

  private get lastPage(): ReactNode {
    const { pagination, onClick } = this.props
    if (pagination.totalPages - 1 <= pagination.pageNumber) return null
    return (
      <>
        <div className="separator" />
        <Button label={ pagination.totalPages.toString() } onClick={ onClick.bind(this, pagination.totalPages) } />
      </>
    )
  }

  private get firstPage(): ReactNode {
    const { pagination, onClick } = this.props
    if (pagination.pageNumber < 3) return null
    return (
      <>
        <Button label="1" onClick={ onClick.bind(this, 1) } />
        <div className="separator" />
      </>
    )
  }

  public render(): ReactNode {
    const { isHidden, pagination } = this.props
    if (isHidden || pagination.totalPages <= 1) return null
    return (
      <ul className="pagination">
        { this.firstPage }
        { this.pages }
        { this.lastPage }
      </ul>
    )
  }
}
