import React, { ReactNode } from 'react'
import { Modal, Title, Button, Field, Input, Select, Spinner } from '../shared'
import { IDraft, DraftModal, IDraftModalProps, IMaterial } from '../../models'
import { IUnit } from '../../models/unit'

interface IProps extends IDraftModalProps<IMaterial> {
  units: IUnit[]
}

// tslint:disable:jsx-no-lambda

export class DraftMaterialModal extends DraftModal<IMaterial, IProps> {
  protected initialDraft: IDraft<IMaterial> = {
    isValid: false,
    value: { name: '', unit: '', unitId: 0, salesPrice: '0', purchasePrice: '0', materialNumber: '' }
  }

  constructor(props) {
    super(props)
    this.state = { draft: this.initialDraft }
  }

  private get isEveryFieldValid(): boolean {
    const { name, unitId } = this.state.draft.value
    return !!name && unitId > 0
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  public render(): ReactNode {
    const { isHidden, onCancel, title, units, isLoading } = this.props
    const draft = this.state.draft
    return (
      <Modal isHidden={ isHidden } className="draft-material-modal">
        <Title label={ title } />
        <div className="fields">
          <Field label="materialNumber">
            <Input
              placeholder="materialNumber"
              value={ draft.value.materialNumber }
              onChange={ x => { this.handleChange({ materialNumber: x }) } }
            />
          </Field>
          <Field label="name">
            <Input
              placeholder="name"
              value={ draft.value.name }
              onChange={ x => { this.handleChange({ name: x }) } }
            />
          </Field>
          <Field label="unit">
            <Select
              selected={ units.find(x => draft.value.unitId && x.id === draft.value.unitId) }
              options={ units }
              labelProp="name"
              onChange={ x => { this.handleChange({ unitId: x.id }) } }
              placeholder="selectUnit"
            />
          </Field>
          <Field label="purchasePrice">
            <Input
              placeholder="purchasePrice"
              type="number"
              step=".01"
              value={ draft.value.purchasePrice }
              onChange={ x => { this.handleChange({ purchasePrice: x }) } }
            />
          </Field>
          <Field label="salesPrice">
            <Input
              placeholder="salesPrice"
              type="number"
              step=".01"
              value={ draft.value.salesPrice }
              onChange={ x => { this.handleChange({ salesPrice: x }) } }
            />
          </Field>
        </div>
        <div className="buttons">
          <Button label={ title } onClick={ this.handleSubmit } isDisabled={ !this.isEveryFieldValid || isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading } />
        </div>
        { this.spinner }
      </Modal>
    )
  }
}
