import React, { PureComponent, ReactNode } from 'react'
import './modal.scss'

interface IProps {
  isHidden?: boolean
  className?: string
}

export class Modal extends PureComponent<IProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    if (nextProps.isHidden) document.body.classList.remove('no-scroll')
    else document.body.classList.add('no-scroll')
  }

  public render(): ReactNode {
    const { isHidden, className, children } = this.props
    if (isHidden) return null
    return (
      <section className={ `modal ${ className }` }>
        <article className="frame">{ children }</article>
      </section>
    )
  }
}
