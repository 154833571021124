import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { ITableProperty } from '../../models'
import { Spinner } from '../shared'
import './table.scss'

interface IProps<T> {
  properties: ITableProperty[]
  items: any[]
}

// tslint:disable:jsx-no-multiline-js
class Table<T> extends PureComponent<IProps<T> & WrappedComponentProps> {
  private get header(): ReactNode {
    const { properties, intl } = this.props
    return properties
      .map((x, i) => (
        <div
          key={ i }
          className={ `column ${ x.className ? x.className : '' }` }
        >{ intl.formatMessage({ id: x.label }) }</div>
      ))
  }

  private get rows(): ReactNode {
    const items = this.props.items
    return items.map((x, i) => <article key={ i } className="row">{ this.cells(x) }</article>)
  }

  public render(): ReactNode {
    const items = this.props.items
    if (!items) return <Spinner />
    if (!items.length) return null
    return (
      <section className="table">
        <article className="row header">{ this.header }</article>
        { this.rows }
      </section>
    )
  }

  private cells(item: any): ReactNode {
    const properties = this.props.properties
    return properties.map((x, i) => (
      <div
        key={ i }
        className={ `column ${ x.className ? x.className : '' }` }
        title={ typeof item[x.key] === 'string' ? item[x.key] : null }
      >{ this.cellContent(item[x.key]) }</div>
    ))
  }

  private cellContent(content: string | ReactNode): ReactNode {
    if (typeof content === 'string') return <p>{ content }</p>
    return content
  }
}

export default injectIntl(Table)
