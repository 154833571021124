import React, { ReactNode } from 'react'
import { DispatchProp, connect } from 'react-redux'
import { autobind } from 'core-decorators'
import { AnyAction } from 'redux'
import { updateIntl, IntlState } from 'react-intl-redux'
import { IStoreState } from '../store/states'
import { MenuItem, LocaleSelector, Icon } from '../components'
import { Routable } from '../models'
import { routeNames } from '../navigation'
import { SessionService } from '../services'
import { literals } from '../literals'
import './menu.scss'
import packageJson from '../../package.json'
import { Version } from '../components/menu/version'
import * as Aviator from 'aviator'

interface IProps {
  intl: IntlState
}

type Props = IProps & DispatchProp<AnyAction>

class Menu extends Routable<Props> {
  private get version(): string {
    const url = process.env.REACT_APP_TIMETRACKER_API_URL
    const suffix = url.includes('staging') ? '-rc' : ''

    return `${packageJson.version}${suffix}`
  }

  private get isReportsPageACtive(): boolean {
      const currentURI = Aviator.getCurrentURI()
      return currentURI.includes('reports')
  }

  public render(): ReactNode {
    return (
      <article className="menu">
        <Icon icon="logo" className="logo" />
        {/* <MenuItem
          label="home"
          icon="home"
          onClick={ this.handleItemClick.bind(this, routeNames.home) }
          active={ this.isURIActive(routeNames.home) }
        /> */}
        <MenuItem
          label="reports"
          icon="reports"
          onClick={ this.handleItemClick.bind(this, `${ routeNames.reports }/${ routeNames.timeRecords }`) }
          active={ this.isReportsPageACtive }
        />
        <MenuItem
          label="workSteps"
          icon="work-steps"
          onClick={ this.handleItemClick.bind(this, routeNames.workSteps) }
          active={ this.isURIActive(routeNames.workSteps) }
        />
        <MenuItem
          label="materials"
          icon="materials"
          onClick={ this.handleItemClick.bind(this, routeNames.materials) }
          active={ this.isURIActive(routeNames.materials) }
        />
        <MenuItem
          label="projects"
          icon="projects"
          onClick={ this.handleItemClick.bind(this, routeNames.projects) }
          active={ this.isURIActive(routeNames.projects) }
        />
        <MenuItem
          label="customers"
          icon="customers"
          onClick={ this.handleItemClick.bind(this, routeNames.customers) }
          active={ this.isURIActive(routeNames.customers) }
        />
        <MenuItem
          label="workers"
          icon="workers"
          onClick={ this.handleItemClick.bind(this, routeNames.workers) }
          active={ this.isURIActive(routeNames.workers) }
        />
        <MenuItem icon="sign-out" label="signOut" onClick={ this.handleSignOutClick } active={ false } />
        <LocaleSelector onClick={ this.handleLocaleChange } active={ this.props.intl.locale } />
        <Version version={ this.version }/>
      </article>
    )
  }

  @autobind
  private handleItemClick(routeName: string): void {
    this.navigate(`/${ routeName }`)
  }

  @autobind
  private handleSignOutClick(): void {
    SessionService.signOut()
  }

  @autobind
  private handleLocaleChange(locale: string): void {
    this.props.dispatch(updateIntl({
      locale,
      messages: literals[locale],
    }))
  }
}

const mapStateToProps = ({ intl }: IStoreState): IProps => ({ intl })

export default connect(mapStateToProps)(Menu)
