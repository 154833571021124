import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { autobind } from 'core-decorators'
import { ITableProperty, CrudPage, IMaterial, MaterialPage, ICrudPageState, emptyPage } from '../../models'
import { IStoreState, ISessionState } from '../../store/states'
import { Menu } from '../../containers'
import {
  Button,
  Panel,
  Table,
  Toggle,
  Field,
  Pagination,
  TableActions,
  DraftMaterialModal,
  PageHeader,
  Input,
  DeleteConfirmationModal
} from '../../components'
import { MaterialService, UnitService } from '../../services'
import { setMaterialsAction } from '../../store/actions'
import { IUnit } from '../../models/unit'
import { Procedure, debounce } from 'ts-debounce'

interface IProps {
  session: ISessionState
  page: MaterialPage
}

interface IState extends ICrudPageState<IMaterial> {
  units: IUnit[]
}

type Props = IProps & DispatchProp<AnyAction>

// tslint:disable:jsx-no-lambda

class WorkStepsPage extends CrudPage<IMaterial, Props, IState> {
  public debouncedFetchUnits: Procedure

  private tableProperties: ITableProperty[] = [
    { key: 'materialNumber', label: 'materialNumber' },
    { key: 'name', label: 'name' },
    { key: 'unit', label: 'unit' },
    { key: 'purchasePrice', label: 'purchasePrice' },
    { key: 'salesPrice', label: 'salesPrice' },
    { key: 'operation', label: 'operation', className: 'no-flex' }
  ]

  private get tableItems(): any[] {
    const items = this.props.page.items
    return items && items.map(x => ({
      materialNumber: x.materialNumber,
      name: x.name,
      unit: x.unit,
      purchasePrice: x.purchasePrice,
      salesPrice: x.salesPrice,
      operation:
        <TableActions onDelete={ () => this.handleOpenDelete(x) } onEdit={ () => this.handleOpenEdit(x) } />
    }))
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.initialState,
      units: []
    }
  }

  public async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.debouncedFetchUnits = debounce(this.fetchUnits, 500)
    this.debouncedFetchUnits()
  }

  public componentWillUnmount(): void {
    super.componentWillUnmount()
    this.props.dispatch(setMaterialsAction(emptyPage))
  }

  public render(): ReactNode {
    const {
      areArchivedIncluded,
      isCreating,
      isEditing,
      isSendingRequest,
      editingItem,
      units,
      filters,
      deletingItem
    } = this.state
    const { page, session } = this.props
    return (
      <section className="page fully-aligned materials">
        <Menu />
        <article className="content">
          <PageHeader session={ session } label="materials" />
          <div className="filters">
            <Button label="new" className="new-button" onClick={ this.handleOpenCreate } />
            <Input
              value={ filters.query.value }
              placeholder="search"
              isInvalid={ !!filters.query.value && !filters.query.isValid }
              onChange={ x => this.handleFilterChange({ query: { value: x, isValid: x.length >= 3 } }) }
            />
          </div>
          <Panel>
            <Table properties={ this.tableProperties } items={ this.tableItems } />
          </Panel>
          <footer>
            <Field label="includeArchieved" >
              <Toggle
                isActive={ areArchivedIncluded }
                onChange={ this.handleArchivedIncludedChange }
              />
            </Field>
            <Pagination
              onClick={ this.fetchItems }
              isHidden={ !page.pagination }
              pagination={ page.pagination }
            />
          </footer>
        </article>
        <DraftMaterialModal
          title={ isCreating ? 'create' : 'save' }
          isHidden={ !isCreating && !isEditing }
          onSubmit={ (isCreating && this.handleCreate) || (isEditing && this.handleEdit) }
          onCancel={ (isCreating && this.handleCancelCreate) || (isEditing && this.handleCancelEdit) }
          item={ editingItem }
          units={ units }
          isLoading={ isSendingRequest }
        />
        <DeleteConfirmationModal
          isHidden={ !deletingItem }
          onCancel={ () => this.handleOpenDelete(null) }
          onConfirm={ this.handleDelete }
          isLoading={ isSendingRequest }
        />
      </section>
    )
  }

  @autobind
  protected async fetchItems(page = 1, areArchivedIncluded = false): Promise<void> {
    try {
      const query = this.state.filters.query
      const result = await MaterialService.getMaterials(page, areArchivedIncluded, query)
      result.items = result.items.map(x => this.formatMaterial(x))
      this.props.dispatch(setMaterialsAction(result))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async handleDelete(): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await MaterialService.deleteMaterial(this.state.deletingItem.id)
      this.setState({ deletingItem: null })
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleCreate(material: IMaterial): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await MaterialService.createMaterial(material)
      await this.fetchItems()
      this.setState({ isCreating: false })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleEdit(material: IMaterial): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await MaterialService.updateMaterial(material)
      await this.fetchItems()
      this.setState({ isEditing: false, editingItem: null })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  private async fetchUnits(): Promise<void> {
    try {
      const units = await UnitService.getUnits()
      this.setState({ units })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }

  private formatMaterial(material: IMaterial): IMaterial {
    return {
      id: material.id,
      materialNumber: material.materialNumber,
      name: material.name,
      unit: material.unit,
      unitId: material.unitId,
      purchasePrice: parseFloat(material.purchasePrice).toFixed(2),
      salesPrice: parseFloat(material.salesPrice).toFixed(2)}
  }
}

const mapStateToProps = ({ session, materials }: IStoreState): IProps => ({
  session,
  page: materials
})

export default connect(mapStateToProps)(WorkStepsPage)
