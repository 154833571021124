import React, { ReactNode, SyntheticEvent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { autobind } from 'core-decorators'
import { updateIntl, IntlState } from 'react-intl-redux'
import { IValidatedInputField, NonAuthenticatedRoutable } from '../../models'
import { IStoreState, ISessionState } from '../../store/states'
import { Input, Button, Link, ErrorMessage, Title, LocaleSelector, Icon } from '../../components'
import {
  setSignInEmailFieldAction,
  setSignInPasswordFieldAction,
  setSignInErrorAction,
  setSessionAction
} from '../../store/actions'
import { SessionService } from '../../services'
import { routeNames } from '../../navigation'
import { literals } from '../../literals'
import './sign-in.scss'
import { isValidUserName } from '../../utils'

interface IProps {
  username: IValidatedInputField<string>
  password: IValidatedInputField<string>
  error: Error
  session: ISessionState
  intl: IntlState
}

interface IState {
  isSigningIn: boolean
}

type Props = IProps & DispatchProp<AnyAction>

class SignInPage extends NonAuthenticatedRoutable<Props, IState> {
  private get areFieldsValid(): boolean {
    const { username, password } = this.props
    return username.isValid && password.isValid && isValidUserName(username.value)
  }

  constructor(props: Props) {
    super(props)
    this.state = { isSigningIn: false }
  }

  public render(): ReactNode {
    const { username, password, error, intl } = this.props
    const isSigningIn = this.state.isSigningIn
    return (
      <section className="page fully-aligned sign-in">
        <article className="side">
          <Icon icon="logo" className="logo" />
        </article>
        <article className="side">
          <form className="content">
            <Title label="signIn" />
            <Input
              placeholder="phoneNumberPlaceholder"
              className="phoneNumber"
              value={ username.value }
              onChange={ this.handleEmailChange }
              isDisabled={ isSigningIn }
            />
            <Input
              placeholder="password"
              type="password"
              className="password"
              value={ password.value }
              onChange={ this.handlePasswordChange }
              isDisabled={ isSigningIn }
            />
            <ErrorMessage error={ error } />
            <Button
              className={ `big ${ isSigningIn ? 'loading' : '' }` }
              label="letsGo"
              onClick={ this.handleSignInClick }
              isDisabled={ !this.areFieldsValid }
            />
            <Link label="forgotPassword" link={ routeNames.forgotPassword } />
          </form>
          <div className="content no-flex">
            <LocaleSelector onClick={ this.handleLocaleChange } active={ intl.locale } />
          </div>
        </article>
      </section>
    )
  }

  @autobind
  private handleEmailChange(value: string): void {
    this.props.dispatch(setSignInEmailFieldAction({ value, isValid: true }))
  }

  @autobind
  private handlePasswordChange(value: string): void {
    this.props.dispatch(setSignInPasswordFieldAction({ value, isValid: true }))
  }

  @autobind
  private async handleSignInClick(event: SyntheticEvent): Promise<void> {
    event.preventDefault()
    const { username, password, dispatch } = this.props
    if (!this.areFieldsValid) return
    this.setState({ isSigningIn: true })
    try {
      const session = await SessionService.signIn(username.value, password.value)
      dispatch(setSessionAction(session))
    } catch (error) {
      dispatch(setSignInErrorAction(new Error('authenticationNotCorrect')))
      this.setState({ isSigningIn: false })
    }
  }

  @autobind
  private handleLocaleChange(locale: string): void {
    this.props.dispatch(updateIntl({
      locale,
      messages: literals[locale],
    }))
  }
}

const mapStateToProps = ({ signIn, session, intl }: IStoreState): IProps => ({
  username: signIn.username,
  password: signIn.password,
  error: signIn.error,
  intl,
  session
})

export default connect(mapStateToProps)(SignInPage)
