import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { AuthenticatedRoutable } from '../models'
import { IStoreState, ISessionState } from '../store/states'
import { Menu } from '../containers'
import { Title } from '../components'
import './not-found.scss'

interface IProps {
  session: ISessionState
}

type Props = IProps & DispatchProp<AnyAction>

class NotFoundPage extends AuthenticatedRoutable<Props> {
  public render(): ReactNode {
    return (
      <section className="page fully-aligned not-found">
        <Menu />
        <article className="content">
          <Title label="pageNotFound" />
        </article>
      </section>
    )
  }
}

const mapStateToProps = ({ session }: IStoreState): IProps => ({
  session
})

export default connect(mapStateToProps)(NotFoundPage)
