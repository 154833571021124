import { autobind } from 'core-decorators'
import { BaseService } from './base'
import {
  TimeRecordPage,
  IWorker,
  ICustomer,
  IProject,
  IWorkStep,
  IRangeDate,
  IValidatedInputField,
  IBlobFile
} from '../../models'
import { formatMultiParam, formatParam } from '../../utils'

class TimeRecordsService extends BaseService {
  private segment = 'timerecords'

  public getTimeRecords(
    page = 1,
    workers?: IWorker[],
    customers?: ICustomer[],
    projects?: IProject[],
    workSteps?: IWorkStep[],
    rangeDate?: IRangeDate,
    query?: IValidatedInputField<string>,
    size = 20
  ): Promise<TimeRecordPage> {
    return this.get(
      `${ this.segment }?page=${ page }&size=${ size }` +
      `${ formatMultiParam(workers, 'workerIds') }` +
      `${ formatMultiParam(customers, 'customerIds') }` +
      `${ formatMultiParam(projects, 'projectIds') }` +
      `${ formatMultiParam(workSteps, 'workStepIds') }` +
      `${ formatParam(rangeDate.from, 'from') }` +
      `${ formatParam(rangeDate.to, 'to') }` +
      `${ formatParam(query.value, 'q', query.isValid) }`
    )
  }

  public deleteTimeRecord(id: number): Promise<void> {
    return this.delete(`${ this.segment }/${ id }`)
  }

  @autobind
  public export(
    workers?: IWorker[],
    customers?: ICustomer[],
    projects?: IProject[],
    workSteps?: IWorkStep[],
    rangeDate?: IRangeDate,
    query?: IValidatedInputField<string>
  ): Promise<IBlobFile> {
    return this.get(`${ this.segment }/download?` +
    `${ formatMultiParam(workers, 'workerIds') }` +
    `${ formatMultiParam(customers, 'customerIds') }` +
    `${ formatMultiParam(projects, 'projectIds') }` +
    `${ formatMultiParam(workSteps, 'workStepIds') }` +
    `${ formatParam(rangeDate.from, 'from') }` +
    `${ formatParam(rangeDate.to, 'to') }` +
    `${ formatParam(query.value, 'q', query.isValid) }`
    )
  }
}

export default new TimeRecordsService()
