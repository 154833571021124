import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { autobind } from 'core-decorators'
import { AnyAction } from 'redux'
import { CrudPage, WorkspacePage, IWorkspace, ITableProperty } from '../../models'
import { IStoreState, ISessionState } from '../../store/states'
import { Menu } from '../../containers'
import {
  Button,
  Panel,
  Field,
  Toggle,
  Pagination,
  Table,
  TableActions,
  DraftWorkspaceModal,
  PageHeader
} from '../../components'
import { WorkspaceService } from '../../services'
import { setWorkspacesAction } from '../../store/actions'

interface IProps {
  session: ISessionState
  page: WorkspacePage
}

type Props = IProps & DispatchProp<AnyAction>

// tslint:disable:jsx-no-lambda

class WorkspacesPage extends CrudPage<IWorkspace, Props> {
  private tableProperties: ITableProperty[] = [
    { key: 'name', label: 'Name' },
    { key: 'trackHours', label: 'Track Hours' },
    { key: 'operation', label: 'Operation', className: 'no-flex' }
  ]

  private get tableItems(): Array<{}> {
    return this.props.page.items.map(x => ({
      name: x.name,
      trackHours: x.trackHours ? 'yes' : 'no',
      operation:
        <TableActions onDelete={ () => this.handleDelete(x) } onEdit={ () => this.handleOpenEdit(x) } />
    }))
  }

  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  public render(): ReactNode {
    const {
      areArchivedIncluded,
      isCreating,
      isEditing,
      isSendingRequest,
      editingItem
    } = this.state
    const { page, session } = this.props
    return (
      <section className="page fully-aligned workspaces">
        <Menu />
        <article className="content">
          <PageHeader session={ session } label="workspaces" />
          <Button label="new" className="new-button" onClick={ this.handleOpenCreate } />
          <Panel isHidden={ !page.items.length }>
            <Table properties={ this.tableProperties } items={ this.tableItems } />
          </Panel>
          <DraftWorkspaceModal
            title={ isCreating ? 'create' : 'save' }
            isHidden={ !isCreating && !isEditing }
            onSubmit={ (isCreating && this.handleCreate) || (isEditing && this.handleEdit) }
            onCancel={ (isCreating && this.handleCancelCreate) || (isEditing && this.handleCancelEdit) }
            item={ editingItem }
            isLoading={ isSendingRequest }
          />
          <footer>
            <Field label="includeArchieved" >
              <Toggle
                isActive={ areArchivedIncluded }
                onChange={ this.handleArchivedIncludedChange }
              />
            </Field>
            <Pagination
              onClick={ this.fetchItems }
              isHidden={ !page.pagination }
              pagination={ page.pagination }
            />
          </footer>
        </article>
      </section>
    )
  }

  @autobind
  protected async fetchItems(page = 1, areArchivedIncluded = false): Promise<void> {
    try {
      const result = await WorkspaceService.getWorkspaces(page, areArchivedIncluded)
      this.props.dispatch(setWorkspacesAction(result))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async handleDelete(workspace: IWorkspace): Promise<void> {
    try {
      await WorkspaceService.deleteWorkspace(workspace.id)
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async handleCreate(workspace: IWorkspace): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await WorkspaceService.createWorkspace(workspace)
      await this.fetchItems()
      this.setState({ isCreating: false })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleEdit(workspace: IWorkspace): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await WorkspaceService.updateWorkspace(workspace)
      await this.fetchItems()
      this.setState({ isEditing: false, editingItem: null })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }
}

const mapStateToProps = ({ session, workspaces }: IStoreState): IProps => ({
  session,
  page: workspaces
})

export default connect(mapStateToProps)(WorkspacesPage)
