import * as React from 'react'
import { configurePageRenderer } from '../utils'
import { SignInPage, ForgotPasswordPage, ResetPasswordPage } from '../pages'

export const authenticationRouteNames = {
  signIn: 'sign-in',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password'
}

export const authenticationPages = {
  [authenticationRouteNames.signIn]: configurePageRenderer(<SignInPage />),
  [authenticationRouteNames.forgotPassword]: configurePageRenderer(<ForgotPasswordPage />),
  [authenticationRouteNames.resetPassword]: configurePageRenderer(<ResetPasswordPage  />)
}

export const authenticationRoutes = {
  [`/${ authenticationRouteNames.signIn }`]: authenticationRouteNames.signIn,
  [`/${ authenticationRouteNames.forgotPassword }`]: authenticationRouteNames.forgotPassword,
  [`/${ authenticationRouteNames.resetPassword }`]: authenticationRouteNames.resetPassword
}
