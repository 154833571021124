import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './item.scss'
import { Icon } from '../shared'

interface IProps extends WrappedComponentProps {
  label: string
  icon: string
  active: boolean
  onClick: () => void
  isHidden?: boolean
}

class MenuItem extends PureComponent<IProps> {
  private get classNames(): string {
    const active = this.props.active
    return `menu-item ${ active ? 'active' : '' }`
  }

  public render(): ReactNode {
    const { isHidden, label, onClick, intl, icon } = this.props
    if (isHidden) return null
    return (
      <div className={ this.classNames } onClick={ onClick }>
        <Icon icon={ icon } />
        <span>{ intl.formatMessage({ id: label }) }</span>
      </div>
    )
  }
}

export default injectIntl(MenuItem)
