import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { updateIntl, IntlState } from 'react-intl-redux'
import { autobind } from 'core-decorators'
import { IValidatedInputField, NonAuthenticatedRoutable } from '../../models'
import { IStoreState, ISessionState } from '../../store/states'
import { Input, Button, Link, Title, MailSentModal, ErrorMessage, LocaleSelector, Icon } from '../../components'
import { routeNames } from '../../navigation'
import { literals } from '../../literals'
import { AuthenticationService } from '../../services'
import './forgot-password.scss'

interface IProps {
  session: ISessionState
  intl: IntlState
}

interface IState {
  email: IValidatedInputField<string>
  isEmailSent: boolean
  error: Error
}

type Props = IProps & DispatchProp<AnyAction>

class ForgotPasswordPage extends NonAuthenticatedRoutable<Props, IState> {
  private get areFieldsValid(): boolean {
    const { email } = this.state
    return email.isValid
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      email: {
        isValid: false,
        value: ''
      },
      error: null,
      isEmailSent: false
    }
  }

  public render(): ReactNode {
    const { email, isEmailSent, error } = this.state
    return (
      <section className="page fully-aligned forgot-password">
        <article className="side">
          <Icon icon="logo" className="logo" />
        </article>
        <article className="side">
          <div className="content">
            <Title label="forgotYourPassword" />
            <Input
              placeholder="email"
              type="text"
              className="email"
              value={ email.value }
              onChange={ this.handleEmailChange }
            />
            <ErrorMessage error={ error } />
            <Button
              className="big"
              label="sendResetLink"
              onClick={ this.handleSendResetLinkClick }
              isDisabled={ !this.areFieldsValid }
            />
            <Link label="backToSignIn" link={ routeNames.signIn } />
          </div>
          <div className="content no-flex">
            <LocaleSelector onClick={ this.handleLocaleChange } active={ this.props.intl.locale } />
          </div>
        </article>
        <MailSentModal isHidden={ !isEmailSent } onClose={ this.handleCloseModal } />
      </section>
    )
  }

  @autobind
  private handleEmailChange(value: string): void {
    this.setState({ email: { value, isValid: true }})
  }

  @autobind
  private handleSendResetLinkClick(): void {
    try {
      AuthenticationService.requestResetPasswordEmail(this.state.email.value)
      this.setState({ isEmailSent: true })
    } catch (error) {
      this.setState({ error })
    }

  }

  @autobind
  private handleCloseModal(): void {
    this.setState({ isEmailSent: false })
    this.navigate(routeNames.signIn)
  }

  @autobind
  private handleLocaleChange(locale: string): void {
    this.props.dispatch(updateIntl({
      locale,
      messages: literals[locale],
    }))
  }
}

const mapStateToProps = ({ session, intl }: IStoreState): IProps => ({
  session,
  intl
})

export default connect(mapStateToProps)(ForgotPasswordPage)
