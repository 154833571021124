import React, { ReactNode, PureComponent } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './text.scss'

interface IProps extends WrappedComponentProps {
  label: string
}

class Text extends PureComponent<IProps> {
  public render(): ReactNode {
    const { label, intl } = this.props
    return  <p className="text">{ intl.formatMessage({ id: label }) }</p>
  }
}

export default injectIntl(Text)
