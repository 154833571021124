import React, { ReactElement } from 'react'
import * as ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-intl-redux'
import logger from 'redux-logger'
import createSagaMiddleware from '@redux-saga/core'

import reducer from './store/reducers'
import saga from './store/sagas'
import { IAviatorDispatchAction } from './models'
import { TelemetryService } from './services'

TelemetryService.initialize('6a0486ec-39b1-43e9-9b09-da269ca4d6c5')

const sagaMiddleware = createSagaMiddleware()
const renderElement = document.getElementById('timetracker')
export const store = createStore(reducer, applyMiddleware(sagaMiddleware, logger))
sagaMiddleware.run(saga)

export function configurePageRenderer(component: ReactElement<any>): (action: IAviatorDispatchAction) => void {
  return action => {
    return ReactDOM.render((
      <Provider store={ store }>
        <component.type { ...component.props } { ...action } />
      </Provider>
    ), renderElement)
  }
}

export function formatParam(value: string | number, param: string, isValid = true): string {
  return !!value && isValid
    ? `&${ param }=${ value }`
    : ''
}

export function formatMultiParam(items: any[], param: string): string {
  return !!items && !!items.length
    ? `&${ param }=${ items.map(x => x.id).join(',') }`
    : ''
}

export function isValidUserName(username: string): boolean {
  return !!username && (username.startsWith('+') || username.startsWith('00') || !Number(username))
}

export function formatInputDate(dateISO: string): string {
  const splittedDate = dateISO.split('T')
  splittedDate.pop()
  return splittedDate[0]
}
