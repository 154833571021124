
import React, { ReactNode } from 'react'
import { DispatchProp, connect } from 'react-redux'
import { AnyAction } from 'redux'
import { Spinner } from '../components'
import { Routable } from '../models'
import { routeNames } from '../navigation'
import { IStoreState, ISignInState } from '../store/states'

interface IProps {
  signIn: ISignInState
}

type Props = IProps & DispatchProp<AnyAction>

export class SplashPage extends Routable<Props> {
  public componentDidMount(): void {
    setTimeout(() => { this.reset(routeNames.signIn) }, 2000)
  }

  public render(): ReactNode {
    return <Spinner />
  }
}

const mapStateToProps = ({ signIn }: IStoreState): IProps => ({
  signIn
})

export default connect(mapStateToProps)(SplashPage)
