import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './link.scss'

interface IProps extends WrappedComponentProps {
  label: string
  link: string
}

class Link extends PureComponent<IProps> {
  public render(): ReactNode {
    const { label, link, intl } = this.props
    return <a href={ link } className="link">{ intl.formatMessage({ id: label }) }</a>
  }
}

export default injectIntl(Link)
