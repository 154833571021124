import React, { PureComponent, ReactNode, createRef } from 'react'
import { autobind } from 'core-decorators'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './select.scss'

interface IProps extends WrappedComponentProps {
  selected: any
  options: any[]
  onChange: (option: any) => void
  placeholder: string
  labelProp?: string
}

interface IState {
  isOpen: boolean
}

class Select extends PureComponent<IProps, IState> {
  private openRef = createRef<HTMLDivElement>()

  private get options(): ReactNode {
    const { options, selected, labelProp } = this.props
    return options.map((x, i) => (
      <li
        className={ `option ${ x === selected ? 'active' : '' }` }
        key={ i }
        onClick={ this.handleOptionClick.bind(this, x) }
      >{ !!labelProp ? x[labelProp] : x }</li>
    ))
  }

  private get classNames(): string {
    const options = this.props.options
    const isOpen = this.state.isOpen
    const isDisabled = !options || !options.length
    return `select ${ isOpen ? 'open' : '' } ${ isDisabled ? 'disabled' : '' }`
  }

  private get selected(): string {
    const { selected, labelProp } = this.props
    return (!labelProp && selected) || (!!labelProp && !!selected && selected[labelProp])
  }

  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false }
    document.addEventListener('click', this.handleDocumentClick)
  }

  public render(): ReactNode {
    const { placeholder, intl } = this.props
    return (
      <div className={ this.classNames }>
        <span
          onClick={ this.handleClick }
          className="selected"
          ref={ this.openRef }
        >{ this.selected || intl.formatMessage({ id: placeholder }) }</span>
        <ul className="options">{ this.options }</ul>
      </div>
    )
  }

  @autobind
  private handleDocumentClick(event: MouseEvent): void {
    if (this.openRef.current && event.target !== this.openRef.current && this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  @autobind
  private handleClick(): void {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen })
  }

  @autobind
  private handleOptionClick(option: any): void {
    this.props.onChange(option)
    this.setState({ isOpen: false })
  }
}

export default injectIntl(Select)
