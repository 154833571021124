import React, { PureComponent, ReactNode } from 'react'
import { IListItem, IListProperty } from '../../models'
import './list.scss'

interface IProps {
  items: IListItem[]
  properties: IListProperty[]
  isHidden: boolean
  className?: string
}

export class List extends PureComponent<IProps> {
  private get rows(): ReactNode {
    const { items, properties } = this.props
    return items.map((x, i) => {
      return (
        <li key={ i } className={ x.className }>
          { properties.map(y => {
            return (
              <div key={ y.key } className={ x.className }>
                { x[y.key] }
              </div>
            )
          }) }
        </li>
      )
    })
  }

  private get header(): ReactNode {
    const { properties, items } = this.props
    return items && items.length
      ? properties.map((x: IListProperty) => <p key={ x.key } className={ x.className }>{ x.label }</p>)
      : null
  }

  public render(): ReactNode {
    const { isHidden, children, className } = this.props

    if (isHidden) return null
    return (
      <div className={ `list ${ className || '' }` }>
        <header>
          { this.header }
        </header>
        <ul className="rows">
          { this.rows }
          { children }
        </ul>
      </div>
    )
  }
}
