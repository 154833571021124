import React, { ReactNode } from 'react'
import { Modal, Title, Button, Field, Input, SearchableSelect, Spinner } from '../shared'
import { IDraft, IProject, DraftModal, IDraftModalProps, ICustomer } from '../../models'

interface IProps extends IDraftModalProps<IProject> {
  customers: ICustomer[]
}

// tslint:disable:jsx-no-lambda

export class DraftProjectModal extends DraftModal<IProject, IProps> {
  protected initialDraft: IDraft<IProject> = {
    isValid: false,
    value: { name: '', customer: null }
  }

  constructor(props) {
    super(props)
    this.state = { draft: this.initialDraft }
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  public render(): ReactNode {
    const { isHidden, onCancel, customers, title, isLoading } = this.props
    const draft = this.state.draft
    return (
      <Modal isHidden={ isHidden } className="draft-project-modal">
        <Title label={ title } />
        <div className="fields">
          <Field label="name">
            <Input
              placeholder="name"
              value={ draft.value.name }
              onChange={ x => { this.handleChange({ name: x }) } }
            />
          </Field>
          <Field label="customer">
            <SearchableSelect
              labelProp={ 'name' }
              selected={ customers.find(x => draft.value.customer && x.id === draft.value.customer.id) }
              options={ customers }
              onChange={ x => { this.handleChange({ customer: x }) } }
              placeholder="selectCustomers"
            />
          </Field>
        </div>
        <div className="buttons">
          <Button label={ title } onClick={ this.handleSubmit } isDisabled={ isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading } />
        </div>
        { this.spinner }
      </Modal>
    )
  }
}
