import React, { PureComponent } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './error-message.scss'

interface IProps extends WrappedComponentProps {
  error: Error
}

class ErrorMessage extends PureComponent<IProps> {
  public render(): React.ReactNode {
    const { error, intl } = this.props
    if (!error) return null
    return <p className="error-message">{ intl.formatMessage({ id: error.message }) }</p>
  }
}

export default injectIntl(ErrorMessage)
