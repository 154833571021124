import React from 'react'
import './version.scss'

interface IProps {
  version: string
}

export function Version(props: IProps) {
  return <small className="version">{ props.version }</small>
}
