import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { AuthenticatedRoutable } from '../models'
import { IStoreState, ISessionState } from '../store/states'
import { Menu } from '../containers'

interface IProps {
  session: ISessionState
}

type Props = IProps & DispatchProp<AnyAction>

class HomePage extends AuthenticatedRoutable<Props> {
  public render(): ReactNode {
    return (
      <section className="page fully-aligned home">
        <Menu />
      </section>
    )
  }
}

const mapStateToProps = ({ session }: IStoreState): IProps => ({
  session
})

export default connect(mapStateToProps)(HomePage)
