import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Procedure, debounce } from 'ts-debounce'
import { format } from 'date-fns'
import { autobind } from 'core-decorators'
import {
  AuthenticatedRoutable,
  IWorker,
  ICustomer,
  IProject,
  IMaterial,
  MaterialRecordPage,
  ITableProperty,
  emptyReportPage,
  IBlobFile, ICrudReport, IMaterialRecord, IWorkStep
} from '../../models'
import { ISessionState, IFiltersState, IStoreState } from '../../store/states'
import { Menu } from '../../containers'
import {
  PageHeader,
  Panel,
  Button,
  Tabs2,
  Pagination,
  Table,
  TableActions,
  DeleteConfirmationModal,
  Input,
  RangeDatePicker,
  TotalTime,
  DraftReportModal,
  SearchableMultiSelect, PrintModal
} from '../../components'
import { MaterialRecordsService, ReportService } from '../../services'
import { resetFiltersAction, setFilterValueAction, setMaterialRecordsAction, setTimeRecordsAction } from '../../store/actions'
import { routeNames } from '../../navigation'
import { IntlState } from 'react-intl-redux'
import { literals } from '../../literals'
import { formatInputDate } from '../../utils'

interface IProps {
  session: ISessionState
  page: MaterialRecordPage
  filters: IFiltersState
  intl: IntlState
}

type Props = IProps & DispatchProp<AnyAction>

interface IState {
  materials: IMaterial[]
  workers: IWorker[]
  customers: ICustomer[]
  projects: IProject[]
  workSteps: IWorkStep[]
  isCreating: boolean
  isEditing: boolean
  isPrinting: boolean
  isSendingRequest: boolean
  editingItem: ICrudReport
  deletingItem: IMaterialRecord
}

// tslint:disable:jsx-no-lambda

class MaterialRecordsPage extends AuthenticatedRoutable<Props, IState> {
  public debouncedFetchItems: Procedure
  private tabOptions = [
    { label: 'timeRecords', onClick: this.handleNavigateToTimeRecords },
    { label: 'materialRecords', onClick: null }
  ]

  private printColumns = ['date', 'project', 'material', 'quantity', 'username']

  private tableProperties: ITableProperty[] = [
    { key: 'worker', label: 'worker' },
    { key: 'customer', label: 'customer' },
    { key: 'project', label: 'project' },
    { key: 'material', label: 'material' },
    { key: 'date', label: 'date' },
    { key: 'quantity', label: 'quantity' },
    { key: 'unit', label: 'unit' },
    { key: 'operation', label: 'operation', className: 'no-flex' }
  ]

  private get tableItems(): any[] {
    const items = this.props.page.items
    return items && items.map(x => ({
      worker: x.worker.fullName,
      customer: x.customer.name,
      project: x.project.name,
      material: x.material.name,
      date: format(new Date(x.date), 'dd/MM/yyyy'),
      quantity: x.quantity,
      unit: x.material.unit,
      operation: (
        <TableActions onDelete={ () => this.handleOpenDelete(x) } onEdit={ () => this.handleOpenEdit(x.reportId) } />
      )
    }))
  }

  private get areFiltersEmpty(): boolean {
    const { filters } = this.props
    return !filters.customers.length
      && !filters.materials.length
      && !filters.projects.length
      && !filters.workers.length
      && !filters.workSteps.length
      && !filters.customers.length
      && !filters.rangeDate.from
      && !filters.rangeDate.to
  }

  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      materials: [],
      projects: [],
      workers: [],
      workSteps: [],
      isCreating: false,
      isEditing: false,
      isPrinting: false,
      isSendingRequest: false,
      editingItem: null,
      deletingItem: null
    }
  }

  public async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.debouncedFetchItems = debounce(this.fetchItems, 500)
    this.debouncedFetchItems()
    await this.fetchDTO()
  }

  public async componentDidUpdate(prevProps: IProps): Promise<void> {
    if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
      await this.debouncedFetchItems()
      await this.fetchDTO()
    }
  }

  public componentWillUnmount(): void {
    this.props.dispatch(setTimeRecordsAction(emptyReportPage))
  }

  public render(): ReactNode {
    const { session, page, filters } = this.props
    const {
      deletingItem,
      workers,
      customers,
      projects,
      materials,
      workSteps,
      isCreating,
      isEditing,
      isPrinting,
      isSendingRequest,
      editingItem
    } = this.state
    const formattedProjectNames = projects.map(p => ({ ...p, name: `${ p.name } - ${ p.customer.name }` }))

    return (
      <section className="page fully-aligned material-reports">
        <Menu />
        <article className="content">
          <PageHeader session={ session } label="reports" />
          <section className="filters">
            <Button label="new" className="inline-new-button" onClick={ this.handleOpenCreate } />
            <SearchableMultiSelect
              labelProp="fullName"
              icon="filter-worker"
              onChange={ x => { this.handleFilterChange({ workers: x }) } }
              options={ workers }
              selected={ filters.workers }
              placeholder="selectWorkers"
            />
            <SearchableMultiSelect
              labelProp={ 'name' }
              icon="filter-customer"
              onChange={ x => { this.handleFilterChange({ customers: x }) } }
              selected={ filters.customers }
              options={ customers }
              placeholder="selectCustomers"
            />
            <SearchableMultiSelect
              labelProp={ 'name' }
              icon="filter-project"
              onChange={ x => { this.handleFilterChange({ projects: x }) } }
              selected={ filters.projects }
              options={ formattedProjectNames }
              placeholder="selectProjects"
            />
            <RangeDatePicker
              rangeDate={ filters.rangeDate }
              onChange={ x => { this.handleFilterChange({ rangeDate: x }) } }
              placeholder="Dates"
            />
            <Input
              value={ filters.query.value }
              isInvalid={ !!filters.query.value && !filters.query.isValid }
              placeholder="search"
              onChange={ this.handleTableSearchChange }
            />
            <Button
              label="resetFilters"
              className="filter push"
              onClick={ this.handleResetFilters }
              isDisabled={ this.areFiltersEmpty }
            />
            <Button
              label="print"
              className="filter middle"
              onClick={ this.handleOpenPrinModal }
              isDisabled={ false }
            />
            <Button
              label="exportToExcel"
              className="filter"
              isDisabled={ false }
              onClick={ this.handleExport }
            />
          </section>
          <Panel>
            <Tabs2 options={ this.tabOptions } active={ this.tabOptions[1] }>
              <article className="filters">
                <SearchableMultiSelect
                  options={ materials }
                  labelProp="name"
                  icon="filter-project"
                  onChange={ x => { this.handleFilterChange({ materials: x }) } }
                  selected={ filters.materials }
                  placeholder="selectMaterials"
                />
                <TotalTime time={ page.totalTime } />
              </article>
              <Table properties={ this.tableProperties } items={ this.tableItems }  />
            </Tabs2>
          </Panel>
          <Pagination
            onClick={ this.fetchItems }
            isHidden={ !page.pagination }
            pagination={ page.pagination }
          />
        </article>
        <DraftReportModal
          title={ isCreating ? 'create' : 'save' }
          isHidden={ !isCreating && !isEditing }
          onSubmit={ (isCreating && this.handleCreate) || (isEditing && this.handleEdit) }
          onCancel={ (isCreating && this.handleCancelCreate) || (isEditing && this.handleCancelEdit) }
          item={ editingItem }
          projects={ projects }
          materials={ materials }
          workSteps={ workSteps }
          isTimeRecordOpened={ false }
          isLoading={ isSendingRequest }
        />
        <DeleteConfirmationModal
          isHidden={ !deletingItem }
          onCancel={ () => this.handleOpenDelete(null) }
          onConfirm={ this.handleDelete }
        />
        <PrintModal
          title="chooseReportColumns"
          isHidden={ !isPrinting }
          columns={ this.printColumns }
          onConfirm={ this.handlePrint }
          onCancel={ this.handleCancelPrint }
          isLoading={ isSendingRequest }
        />
      </section>
    )
  }

  @autobind
  protected async fetchItems(pageNumber = 1): Promise<void> {
    try {
      const { customers, projects, workers, materials, rangeDate, query } = this.props.filters
      const page =
        await MaterialRecordsService
          .getMaterialRecords(pageNumber, workers, customers, projects, materials, rangeDate, query)
      this.props.dispatch(setMaterialRecordsAction(page))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async fetchDTO(): Promise<void> {
    try {
      const selectedCustomers = this.props.filters.customers
      const initialFilters = await ReportService.getReportFilters(selectedCustomers)
      const { customers, materials, projects, workers, workSteps } = initialFilters
      this.setState({ customers, materials, projects, workers, workSteps })
    } catch (error) {
      throw error
    }
  }

  @autobind
  private async handleCreate(crudReport: ICrudReport): Promise<void> {
    try {
      const timeRecords = crudReport.timeRecords.map(record => ({
        workstepId: record.workStep.id,
        description: record.description,
        startTime: record.startTime,
        endTime: record.endTime
      }))
      const materialRecords = crudReport.materialRecords.map(record => ({
        materialId: record.material.id,
        quantity: record.quantity
      }))
      const report = {
        projectId: crudReport.projectId,
        date: crudReport.date,
        timeRecords,
        materialRecords
      }
      this.setState({ isSendingRequest: true })

      await ReportService.createReport(report)
      this.props.dispatch(setMaterialRecordsAction(emptyReportPage))
      this.setState({ isCreating: false })
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  private async handleEdit(crudReport: ICrudReport): Promise<void> {
    try {
      const timeRecords = crudReport.timeRecords.map(record => ({
        workstepId: record.workStep.id,
        description: record.description,
        startTime: record.startTime,
        endTime: record.endTime
      }))
      const materialRecords = crudReport.materialRecords.map(record => ({
        materialId: record.material.id,
        quantity: record.quantity
      }))
      const report = {
        id: crudReport.id,
        projectId: crudReport.projectId,
        date: crudReport.date,
        timeRecords,
        materialRecords
      }
      this.setState({ isSendingRequest: true })

      await ReportService.updateReport(report)
      this.props.dispatch(setMaterialRecordsAction(emptyReportPage))
      this.setState({ isEditing: false, editingItem: null })
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  private async handleExport(): Promise<void> {
    const { filters } = this.props
    const { customers, projects, workers, materials, rangeDate, query } = filters
    const data = await MaterialRecordsService.export(workers, customers, projects, materials, rangeDate, query)
    this.handleDownloadBlob(data)
  }

  @autobind
  private handleDownloadBlob(data: IBlobFile): void {
    const url = window.URL.createObjectURL(data.blob)
    const element = document.createElement('a')
    element.href = url
    element.download = data.filename
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  @autobind
  private async handlePrint(columns: string[]): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      const { page, filters, intl } = this.props
      const { customers, projects, workers, materials, rangeDate, query } = filters

      const records = await MaterialRecordsService
        .getMaterialRecords(1, workers, customers, projects, materials, rangeDate, query, page.pagination.totalItems)
      records.startDate = format(new Date(records.startDate), 'dd/MM/yyyy')
      records.endDate = format(new Date(records.endDate), 'dd/MM/yyyy')
      records.items = records.items.map(x => ({ ...x, date: format(new Date(x.date), 'dd/MM/yyyy') }))

      localStorage.setItem('timetracker-print-columns', JSON.stringify(columns))
      localStorage.setItem('timetracker-print-records', JSON.stringify(records))
      localStorage.setItem('timetracker-print-records-translations', JSON.stringify(literals[intl.locale]))

      window.open(`/print-material-records.html`)
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error)
    } finally {
      await this.fetchItems()
      this.setState({ isSendingRequest: false, isPrinting: false })
    }
  }

  @autobind
  private handleFilterChange(filter: { [key: string]: any }): void {
    this.props.dispatch(setMaterialRecordsAction(emptyReportPage))
    this.props.dispatch(setFilterValueAction(filter))
  }

  @autobind
  private handleResetFilters(): void {
    this.props.dispatch(setMaterialRecordsAction(emptyReportPage))
    this.props.dispatch(resetFiltersAction())
  }

  @autobind
  private handleTableSearchChange(value: string): void {
    this.handleFilterChange({
      query: {
        value,
        isValid: value.length > 3
      }
    })
  }

  @autobind
  private handleOpenCreate(): void {
    this.setState({
      isCreating: true,
      editingItem: {
        projectId: 0,
        date: '',
        timeRecords: [],
        materialRecords: []
      }
    })
  }

  @autobind
  private async handleOpenEdit(id: number): Promise<void> {
    try {
      const report = await ReportService.getReportById(id)
      const crudItem: ICrudReport = {
        id: report.id,
        date: formatInputDate(report.date),
        projectId: report.projectId,
        timeRecords: report.timeRecords.map(tr => {
          const startTime = tr.startTime.split(':')
          const endTime = tr.endTime.split(':')
          startTime.pop()
          endTime.pop()

          return {
            workStep: { id: tr.workStepId, name: tr.workStepName },
            description: tr.description,
            startTime: startTime.join(':'),
            endTime: endTime.join(':')
          }
        }),
        materialRecords: report.materialRecords.map(mr => ({
          material: { id: mr.materialId, name: mr.materialName },
          quantity: mr.quantity
        }))
      }
      this.setState({ isEditing: true, editingItem: crudItem })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  private handleOpenPrinModal(): void {
    this.setState({ isPrinting: true })
  }

  @autobind
  private handleOpenDelete(item: IMaterialRecord): void {
    this.setState({ deletingItem: item })
  }

  @autobind
  private handleCancelCreate(): void {
    this.setState({ isCreating: false })
  }

  @autobind
  private handleCancelEdit(): void {
    this.setState({ isEditing: false, editingItem: null })
  }

  @autobind
  private handleCancelPrint(): void {
    this.setState({ isPrinting: false })
  }

  @autobind
  private handleNavigateToTimeRecords(): void {
    this.navigate(routeNames.timeRecords)
  }

  @autobind
  private async handleDelete(): Promise<void> {
    try {
      await MaterialRecordsService.deleteMaterialRecord(this.state.deletingItem.id)
      await this.fetchItems()
      this.setState({ deletingItem: null })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }
}

const mapStateToProps = ({ session, materialRecords, filters, intl }: IStoreState): IProps => ({
  session,
  page: materialRecords,
  filters,
  intl
})

export default connect(mapStateToProps)(MaterialRecordsPage)
