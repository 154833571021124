import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { IntlState, updateIntl } from 'react-intl-redux'
import { autobind } from 'core-decorators'
import { IValidatedInputField, NonAuthenticatedRoutable, IAviatorDispatchAction } from '../../models'
import { IStoreState, ISessionState } from '../../store/states'
import { Input, Button, Link, Title, ErrorMessage, PasswordResetModal, LocaleSelector, Icon } from '../../components'
import { routeNames } from '../../navigation'
import { AuthenticationService } from '../../services'
import { literals } from '../../literals'
import './reset-password.scss'

interface IProps {
  session: ISessionState
  intl: IntlState
}

interface IState {
  newPassword: IValidatedInputField<string>
  confirmNewPassword: IValidatedInputField<string>
  error: Error
  isPasswordReset: boolean
}

type Props = IProps & DispatchProp<AnyAction> & IAviatorDispatchAction

class ResetPasswordPage extends NonAuthenticatedRoutable<Props, IState> {
  private get areFieldsValid(): boolean {
    const { newPassword, confirmNewPassword } = this.state
    return newPassword.isValid && confirmNewPassword.isValid
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      newPassword: {
        isValid: false,
        value: ''
      },
      confirmNewPassword: {
        isValid: false,
        value: ''
      },
      error: null,
      isPasswordReset: false
    }
  }

  public componentDidMount(): void {
    super.componentDidMount()
    const queryParams = this.props.queryParams
    if (!queryParams || !queryParams.token || !queryParams.username) this.reset(routeNames.signIn)
  }

  public render(): ReactNode {
    const { newPassword, confirmNewPassword, error, isPasswordReset } = this.state
    return (
      <section className="page fully-aligned reset-password">
        <article className="side">
          <Icon icon="logo" className="logo" />
        </article>
        <article className="side">
          <div className="content">
            <Title label="resetYourPassword" />
            <Input
              placeholder="newPassword"
              type="text"
              className="password"
              value={ newPassword.value }
              onChange={ this.handleNewPasswordChange }
            />
            <Input
              placeholder="confirmNewPassword"
              type="text"
              className="password-confirmation"
              value={ confirmNewPassword.value }
              onChange={ this.handleConfirmNewPasswordChange }
            />
            <ErrorMessage error={ error } />
            <Button
              className="big"
              label="done"
              onClick={ this.handleSendNewPasswordClick }
              isDisabled={ !this.areFieldsValid }
            />
            <Link label="backToSignIn" link={ routeNames.signIn } />
            <PasswordResetModal isHidden={ !isPasswordReset } onClose={ this.handleCloseModal } />
          </div>
          <div className="content no-flex">
            <LocaleSelector onClick={ this.handleLocaleChange } active={ this.props.intl.locale } />
          </div>
        </article>
      </section>
    )
  }

  @autobind
  private handleNewPasswordChange(value: string): void {
    this.setState({ newPassword: { value, isValid: true }})
  }

  @autobind
  private handleConfirmNewPasswordChange(value: string): void {
    const isValid = value === this.state.newPassword.value
    this.setState({
      confirmNewPassword: { value, isValid },
      error: isValid ? null : new Error('passwordsDontMatch')
    })
  }

  @autobind
  private async handleSendNewPasswordClick(): Promise<void> {
    const { token, username } = this.props.queryParams
    try {
      await AuthenticationService.resetPassword(username, this.state.newPassword.value, token)
      this.setState({ isPasswordReset: true })
    } catch (error) {
      this.setState({ error })
    }
  }

  @autobind
  private handleCloseModal(): void {
    this.setState({ isPasswordReset: false })
    this.navigate(routeNames.signIn)
  }

  @autobind
  private handleLocaleChange(locale: string): void {
    this.props.dispatch(updateIntl({
      locale,
      messages: literals[locale],
    }))
  }
}

const mapStateToProps = ({ session, intl }: IStoreState): IProps => ({
  session,
  intl
})

export default connect(mapStateToProps)(ResetPasswordPage)
