import * as React from 'react'
import { configurePageRenderer } from '../utils'
import { WorkStepsPage, ProjectsPage, CustomersPage, MaterialsPage, WorkersPage } from '../pages'

export const managementRouteNames = {
  workSteps: 'work-steps',
  projects: 'projects',
  customers: 'customers',
  materials: 'materials',
  workers: 'workers'
}

export const managementPages = {
  [managementRouteNames.workSteps]: configurePageRenderer(<WorkStepsPage />),
  [managementRouteNames.projects]: configurePageRenderer(<ProjectsPage />),
  [managementRouteNames.customers]: configurePageRenderer(<CustomersPage />),
  [managementRouteNames.materials]: configurePageRenderer(<MaterialsPage />),
  [managementRouteNames.workers]: configurePageRenderer(<WorkersPage />)
}

export const managementRoutes = {
  [`/${ managementRouteNames.workSteps }`]: managementRouteNames.workSteps,
  [`/${ managementRouteNames.projects }`]: managementRouteNames.projects,
  [`/${ managementRouteNames.customers }`]: managementRouteNames.customers,
  [`/${ managementRouteNames.materials }`]: managementRouteNames.materials,
  [`/${ managementRouteNames.workers }`]: managementRouteNames.workers
}
