// tslint:disable:max-line-length

export const literals: { [key: string]: { [key: string]: string } } = {
  de: {
    signIn: 'Anmelden',
    backToSignIn: 'Zurück zum Login',
    signOut: 'Abmelden',
    reports: 'Berichte  ',
    workSteps: 'Arbeitsschritte',
    home: 'Home',
    forgotPassword: 'Passwort vergessen?',
    email: 'Email',
    password: 'Password',
    letsGo: 'Anmelden',
    selectWorkers: 'Arbeiter',
    selectCustomers: 'Kunden',
    selectProjects: 'Projekte',
    selectWorkSteps: 'Arbeitsschritte',
    selectMaterials: 'Material',
    print: 'Drucken',
    exportToExcel: 'Excel Export',
    timeRecords: 'Zeiteinträge',
    materialRecords: 'Materialeinträge',
    new: 'Neu',
    material: 'Material',
    unit: 'Einheit',
    name: 'Name',
    workspaceName: 'Workspace Name',
    create: 'Erstellen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    includeArchieved: 'Archivierte Einträge anzeigen',
    projects: 'Projekte',
    customers: 'Kunden',
    customer: 'Kunde',
    workspaces: 'Workspaces',
    workspace: 'Workspace',
    trackHours: 'Stundenbasiert',
    materials: 'Material',
    workers: 'Arbeiter',
    username: 'Benutzername',
    role: 'Rolle',
    selectRole: 'Rolle auswählen',
    sendResetLink: 'Passwort zurück senden',
    forgotYourPassword: 'Passwort vergessen?',
    enterYouAccountEmail: 'Bitte gebe deine Email Adresse ein. Wir senden dir eine Email mit den nächsten Schritten.',
    authenticationNotCorrect: 'Benutzername oder Passwort sind nicht korrekt.',
    multipleValuesSelected: 'Mehrere Werte ausgewählt',
    resetPasswordMailSent: 'Email wurde gesendet',
    forgotPasswordEmailSent: 'Eine Email mit denen Schritten um dein Passwort zurückzusetzen wurde dir zurgesendet.',
    resetYourPassword: 'Passwort zurücksetzen',
    newPassword: 'Neues Passwort',
    confirmNewPassword: 'Neues Passwort bestätigen',
    done: 'Ok',
    passwordsDontMatch: 'Passwörter stimmen nicht überein, bitte nochmal eingeben.',
    passwordReset: 'Passwort zurücksetzen',
    passwordResetSuccessfully: 'Das Passwort wurde erfolgreich zurück gesetzt.',
    selectUnit: 'Einheit auswählen',
    search: 'Suche',
    areYouSureDeleteItem: 'Bist du sicher, dass du diesen Eintrag löschen möchtest?',
    confirmation: 'Bestätigung',
    confirm: 'Bestätigen',
    totalTime: 'Zeit',
    pageNotFound: 'Seite nicht gefunden',
    operation: 'Aktionen',
    description: 'Beschreibung',
    from: 'Von',
    to: 'Bis',
    date: 'Datum',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    workStep: 'Arbeitsschritt',
    project: 'Projekt',
    worker: 'Arbeiter',
    customerName: 'Kunde',
    quantity: 'Menge',
    firstName: 'Vorname',
    lastName: 'Nachname',
    fullName: 'Name',
    phoneNumber: 'Handynummer',
    phoneNumberPlaceholder: 'Handynummer mit Landesvorwahl (+32476123123)',
    confirmPassword: 'Passwort bestätigen',
    salesPrice: 'Verkaufspreis',
    purchasePrice: 'Einkaufspreis',
    materialNumber: 'Materialnummer',
    selectProject: 'Projekt auswählen',
    selectWorkStep: 'Arbeitsschritt auswählen',
    selectMaterial: 'Material auswählen',
    resetFilters: 'Filter zurücksetzen',
    chooseReportColumns: 'Bericht Kolonnen auswählen',
    thisWeek: 'Diese Woche',
    lastWeek: 'Letzte Woche',
    thisMonth: 'Diesen Monat',
    lastMonth: 'Letzter Monat'
  },
  en: {
    signIn: 'Sign in',
    backToSignIn: 'Back to sign in',
    signOut: 'Sign out',
    reports: 'Reports',
    workSteps: 'Work steps',
    home: 'Home',
    forgotPassword: 'Forgot password?',
    email: 'Email',
    password: 'Password',
    letsGo: 'Let\'s go!',
    selectWorkers: 'Select workers',
    selectCustomers: 'Select customers',
    selectProjects: 'Select projects',
    selectWorkSteps: 'Select work steps',
    selectMaterials: 'Select materials',
    print: 'Print',
    exportToExcel: 'Export to excel',
    timeRecords: 'Time records',
    materialRecords: 'Material records',
    new: 'New',
    material: 'Material',
    unit: 'Unit',
    name: 'Name',
    workspaceName: 'Workspace name',
    create: 'Create',
    cancel: 'Cancel',
    save: 'Save',
    includeArchieved: 'Include archieved',
    projects: 'Projects',
    customers: 'Customers',
    customer: 'Customer',
    workspaces: 'Workspaces',
    workspace: 'Workspace',
    trackHours: 'Track hours',
    materials: 'Materials',
    workers: 'Workers',
    username: 'Username',
    role: 'Role',
    selectRole: 'Select Role',
    sendResetLink: 'Send reset link',
    forgotYourPassword: 'Forgot your password?',
    enterYouAccountEmail: 'Please enter your account email address. We\'ll send you an email with password reset instructions',
    authenticationNotCorrect: 'The username or password that you entered was not correct',
    multipleValuesSelected: 'Multiple values selected',
    resetPasswordMailSent: 'Reset password mail sent',
    forgotPasswordEmailSent: 'We have sent an email to your registered e-mail address with details for resetting your password.',
    resetYourPassword: 'Reset your password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm new password',
    done: 'Done',
    passwordsDontMatch: 'New password and confirm password do not match, please try again.',
    passwordReset: 'Password reset',
    passwordResetSuccessfully: 'Your password has been reseted successfully',
    selectUnit: 'Select unit',
    search: 'Search',
    areYouSureDeleteItem: 'Are you sure you want to delete this item?',
    confirmation: 'Confirmation',
    confirm: 'Confirm',
    totalTime: 'Total time',
    pageNotFound: 'Page not found',
    operation: 'Operation',
    description: 'Description',
    from: 'From',
    to: 'To',
    date: 'Date',
    startTime: 'Start time',
    endTime: 'End time',
    workStep: 'Work step',
    project: 'Project',
    worker: 'Worker',
    customerName: 'Customer name',
    quantity: 'Quantity',
    firstName: 'First name',
    lastName: 'Last name',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: 'phone number with prefix (ex. +32476123123)',
    confirmPassword: 'Confirm password',
    salesPrice: 'Selling price',
    purchasePrice: 'Purchase price',
    materialNumber: 'Material number',
    selectProject: 'Select Project',
    selectWorkStep: 'Select Work Step',
    selectMaterial: 'Select Material',
    resetFilters: 'Reset Filters',
    chooseReportColumns: 'Choose report columns',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month'
  },
  fr: {
    signIn: 'Sign in',
    backToSignIn: 'Back to sign in',
    signOut: 'Sign out',
    reports: 'Reports',
    workSteps: 'Work steps',
    home: 'Home',
    forgotPassword: 'Forgot password?',
    email: 'Email',
    password: 'Password',
    letsGo: 'Let\'s go!',
    selectWorkers: 'Select workers',
    selectCustomers: 'Select customers',
    selectProjects: 'Select project',
    selectWorkSteps: 'Select work steps',
    selectMaterials: 'Select materials',
    print: 'Print',
    exportToExcel: 'Export to excel',
    timeRecords: 'Time records',
    materialRecords: 'Material records',
    new: 'New',
    material: 'Material',
    unit: 'Unit',
    name: 'Name',
    workspaceName: 'Workspace name',
    create: 'Create',
    cancel: 'Cancel',
    save: 'Save',
    includeArchieved: 'Include archieved',
    projects: 'Projects',
    customers: 'Customers',
    customer: 'Customer',
    workspaces: 'Workspaces',
    workspace: 'Workspace',
    trackHours: 'Track hours',
    materials: 'Materials',
    workers: 'Workers',
    username: 'Username',
    role: 'Role',
    selectRole: 'Choisir un rôle',
    sendResetLink: 'Send reset link',
    forgotYourPassword: 'Forgot your password?',
    enterYouAccountEmail: 'Please enter your account email address. We\'ll send you an email with password reset instructions',
    authenticationNotCorrect: 'The username or password that you entered was not correct',
    multipleValuesSelected: 'Multiple values selected',
    resetPasswordMailSent: 'Reset password mail sent',
    forgotPasswordEmailSent: 'We have sent an email to your registered e-mail address with details for resetting your password.',
    resetYourPassword: 'Reset your password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm new password',
    done: 'Done',
    passwordsDontMatch: 'New password and confirm password do not match, please try again.',
    passwordReset: 'Password reset',
    passwordResetSuccessfully: 'Your password has been reseted successfully',
    selectUnit: 'Select unit',
    search: 'Search',
    areYouSureDeleteItem: 'Are you sure you want to delete this item?',
    confirmation: 'Confirmation',
    confirm: 'Confirm',
    totalTime: 'Total time',
    pageNotFound: 'Page not found',
    operation: 'Operation',
    description: 'Description',
    from: 'De',
    to: 'A',
    date: 'Date',
    startTime: 'Start time',
    endTime: 'End time',
    workStep: 'Work step',
    project: 'Project',
    worker: 'Worker',
    customerName: 'Customer name',
    quantity: 'Quantity',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    fullName: 'Nom',
    phoneNumber: 'Numéro de portable',
    phoneNumberPlaceholder: 'Numéro de portable avec code du pays (+32476123123)',
    confirmPassword: 'Confirmer le mot de passe',
    salesPrice: 'Prix de vente',
    purchasePrice: 'Prix d\'achat',
    materialNumber: 'Numéro d\'article',
    selectProject: 'Sélectionnez un projet',
    selectWorkStep: 'Sélectionnez l\'étape de travail',
    selectMaterial: 'Sélectionnez un matériau',
    resetFilters: 'Réinitialiser les filtres',
    chooseReportColumns: 'Choisir les colonnes du rapport',
    thisWeek: 'Cette semaine',
    lastWeek: 'La semaine dernière',
    thisMonth: 'Ce mois-ci',
    lastMonth: 'Le mois dernier'
  }
}
