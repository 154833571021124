import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './title.scss'

interface IProps extends WrappedComponentProps {
  label: string
}

class Title extends PureComponent<IProps> {
  public render(): ReactNode {
    const { label, intl } = this.props
    return <h3 className="title">{ intl.formatMessage({ id: label }) }</h3>
  }
}

export default injectIntl(Title)
