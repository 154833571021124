import React, { ReactNode, PureComponent } from 'react'
import { Modal, Title, Button, Text, Spinner } from '../shared'
import './delete-confirmation-modal.scss'

interface IProps {
  onCancel: () => void
  onConfirm: () => void
  isHidden: boolean
  isLoading?: boolean
}

export class DeleteConfirmationModal extends PureComponent<IProps> {
  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  public render(): ReactNode {
    const { onCancel, onConfirm, isHidden, isLoading } = this.props
    return (
      <Modal isHidden={ isHidden } className="delete-confirmation-modal">
        <Title label="confirmation" />
        <Text label="areYouSureDeleteItem" />
        <div className="buttons">
          <Button label="confirm" onClick={ onConfirm } isDisabled={ isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading } />
        </div>
        { this.spinner }
      </Modal>
    )
  }
}
