import React, { PureComponent, ReactNode, createRef } from 'react'
import { autobind } from 'core-decorators'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './searchable-select.scss'

interface IProps extends WrappedComponentProps {
  selected: any
  options: any[]
  onChange: (option: any) => void
  placeholder: string
  labelProp?: string
}

interface IState {
  isOpen: boolean
  optionFilter: string
}

class SearchableSelect extends PureComponent<IProps, IState> {
  private openRef = createRef<HTMLDivElement>()
  private inputRef = createRef<HTMLInputElement>()

  private get options(): ReactNode {
    const { options, selected, labelProp } = this.props
    const { optionFilter } = this.state
    const filteredOptions = !!optionFilter
      ? options.filter(option => {
          return !!labelProp
            ? option[labelProp].toLowerCase().includes(optionFilter.toLowerCase())
            : option.includes(optionFilter)
        })
      : [...options]
    return filteredOptions.map((x, i) => (
      <li
        className={ `option ${ x === selected ? 'active' : '' }` }
        key={ i }
        onClick={ this.handleOptionClick.bind(this, x) }
      >{ !!labelProp ? x[labelProp] : x }</li>
    ))
  }

  private get classNames(): string {
    const options = this.props.options
    const isOpen = this.state.isOpen
    const isDisabled = !options || !options.length
    return `searchable-select ${ isOpen ? 'open' : '' } ${ isDisabled ? 'disabled' : '' }`
  }

  private get selected(): string {
    const { selected, labelProp } = this.props
    return (!labelProp && selected) || (!!labelProp && !!selected && selected[labelProp])
  }

  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false, optionFilter: '' }
    document.addEventListener('click', this.handleDocumentClick)
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!prevState.isOpen && this.state.isOpen && this.inputRef) this.inputRef.current.focus()
  }

  public render(): ReactNode {
    const { placeholder, intl } = this.props
    return (
      <div className={ this.classNames }>
        <span
          onClick={ this.handleClick }
          className="selected"
          ref={ this.openRef }
        >{ this.selected || intl.formatMessage({ id: placeholder }) }</span>
        <div className="options-container">
          <input
            ref={ this.inputRef }
            className="search"
            type="text"
            placeholder={ intl.formatMessage({ id: 'search' }) }
            // tslint:disable-next-line: jsx-no-lambda
            onChange={ x => this.handleOptionSearch(x.currentTarget.value) }
          />
          <ul className="options">
            { this.options }
          </ul>
        </div>
      </div>
    )
  }

  @autobind
  private handleDocumentClick(event: MouseEvent): void {
    if (
      this.openRef.current &&
      this.inputRef.current &&
      event.target !== this.openRef.current &&
      event.target !== this.inputRef.current &&
      this.state.isOpen
    ) {
      this.setState({ isOpen: false, optionFilter: '' })
    }
  }

  @autobind
  private handleClick(): void {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen, optionFilter: '' })
  }

  @autobind
  private handleOptionClick(option: any): void {
    this.props.onChange(option)
    this.setState({ isOpen: false, optionFilter: '' })
  }

  @autobind
  private handleOptionSearch(value: string): void {
    this.setState({ optionFilter: value })
  }
}

export default injectIntl(SearchableSelect)
