import { autobind } from 'core-decorators'
import { debounce, Procedure } from 'ts-debounce'
import { AuthenticatedRoutable, IAuthenticatedRoutableProps } from './authenticated-routable'
import { IItemPage } from './pagination'
import { resetFiltersAction } from '../store/actions'
import { IFiltersState } from '../store/states'
import { initialState as filtersInitialState } from '../store/reducers/filters'

export interface ICrudPageState<T> {
  isCreating: boolean
  isEditing: boolean
  isSendingRequest: boolean
  editingItem: T
  areArchivedIncluded: boolean
  filters: IFiltersState
  deletingItem: T
}

interface IProps<T> extends IAuthenticatedRoutableProps {
  page: IItemPage<T>
}

export abstract class CrudPage<
  T,
  P extends IProps<T> = IProps<T>,
  S extends ICrudPageState<T> = ICrudPageState<T>
> extends AuthenticatedRoutable<P, S> {
  public debouncedFetchItems: Procedure

  protected initialState: ICrudPageState<T> = {
    isCreating: false,
    isEditing: false,
    isSendingRequest: false,
    editingItem: null,
    areArchivedIncluded: false,
    filters: filtersInitialState,
    deletingItem: null
  }

  public async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.debouncedFetchItems = debounce(this.fetchItems, 300)
    await this.debouncedFetchItems()
  }

  public async componentDidUpdate(_, prevState: ICrudPageState<any>): Promise<void> {
    if (JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)) await this.debouncedFetchItems()
  }

  public componentWillUnmount(): void {
    this.props.dispatch(resetFiltersAction())
  }

  @autobind
  protected handleOpenDelete(item: T): void {
    this.setState({ deletingItem: item })
  }

  @autobind
  protected handleOpenCreate(): void {
    this.setState({ isCreating: true, editingItem: {} as any as T })
  }

  @autobind
  protected handleOpenEdit(item: T): void {
    this.setState({ isEditing: true, editingItem: item })
  }

  @autobind
  protected handleCancelCreate(): void {
    this.setState({ isCreating: false })
  }

  @autobind
  protected handleCancelEdit(): void {
    this.setState({ isEditing: false, editingItem: null })
  }

  @autobind
  protected async handleArchivedIncludedChange(areArchivedIncluded: boolean): Promise<void> {
    this.setState({ areArchivedIncluded })
    await this.fetchItems(this.props.page.pagination.pageNumber, areArchivedIncluded)
  }

  @autobind
  protected handleFilterChange(filter: { [key: string]: any }): void {
    this.setState({ filters: { ...this.state.filters, ...filter } })
  }

  protected abstract async fetchItems(page?: number, areArchivedIncluded?: boolean): Promise<void>
  protected abstract async handleDelete(item: T): Promise<void>
  protected abstract async handleCreate(item: T): Promise<void>
  protected abstract async handleEdit(item: T): Promise<void>
}
