import React, { PureComponent, ReactNode } from 'react'
import { Title } from '.'
import { ISession } from '../../models'
import './page-header.scss'

interface IProps {
  label: string
  session: ISession
}

export class PageHeader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { label, session } = this.props
    if (!session) return null
    return (
      <section className="page-header">
        <Title label={ label } />
        <span className="session-name">{ session.name }</span>
      </section>
    )
  }
}
