import React, { PureComponent, ReactNode, ChangeEvent } from 'react'
import { autobind } from 'core-decorators'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './input.scss'

export interface IProps {
  placeholder: string
  value: string
  type?: string
  step?: string
  className?: string
  isDisabled?: boolean
  isInvalid?: boolean
  onChange?: (value: string) => void
}

export class Input<P extends IProps> extends PureComponent<P & WrappedComponentProps> {
  private get className(): string {
    const { isInvalid, className } = this.props
    return `input ${ isInvalid ? 'invalid' : '' } ${ !!className ? className : '' }`
  }

  public render(): ReactNode {
    const { placeholder, value, isDisabled, type = 'text', intl, step } = this.props
    return (
      <input
        className={ this.className }
        type={ type }
        step={ step }
        value={ value }
        onChange={ this.handleChange }
        disabled={ isDisabled }
        placeholder={ intl.formatMessage({ id: placeholder }) }
      />
    )
  }

  @autobind
  private handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const onChange = this.props.onChange
    if (onChange) onChange(event.currentTarget.value)
  }
}

export default injectIntl(Input)
