import { autobind } from 'core-decorators'
import React, { PureComponent, ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Modal, Spinner, Title } from '../shared'

interface IProps extends WrappedComponentProps {
  title: string
  columns: string[]
  isHidden: boolean
  onConfirm: (columns: string[]) => void
  onCancel: () => void
  isLoading?: boolean
}

interface IState {
  selectedColumns: string[]
}

// tslint:disable: jsx-key
// tslint:disable: jsx-no-lambda
class PrintModal extends PureComponent<IProps, IState> {
  private get fields(): ReactNode {
    const { columns, intl, isLoading } = this.props
    const selected = this.state.selectedColumns
    return columns.map(column => (
      <div className="field" onClick={ () => this.handleCheckboxChange(column) }>
        <input
          className="checkbox"
          type="checkbox"
          checked={ selected.includes(column) }
          // tslint:disable-next-line: no-console
          onChange={ console.log }
          disabled={ isLoading }
        />
        <label className="label">{ intl.formatMessage({ id: column }) }</label>
      </div>
    ))
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  constructor(props: IProps) {
    super(props)
    this.state = { selectedColumns: this.props.columns }
  }

  public render() {
    const { title, onCancel, isHidden, isLoading } = this.props
    return (
      <Modal isHidden={ isHidden } className="print-modal">
        <Title label={ title } />
        <div className="fields">
          { this.fields }
        </div>
        <div className="buttons">
          <Button label="confirm" onClick={ this.handleConfirm } isDisabled={ isLoading } />
          <Button label="cancel" onClick={ onCancel } isDisabled={ isLoading }/>
        </div>
        { this.spinner }
      </Modal>
    )
  }

  @autobind
  private handleCheckboxChange(value: string): void {
    const currentSelected = this.state.selectedColumns
    const selectedColumns = currentSelected.includes(value)
      ? currentSelected.filter(c => c !== value)
      : [...currentSelected, value]

    this.setState({ selectedColumns })
  }

  @autobind
  private handleConfirm(): void {
    const { onConfirm, columns } = this.props
    const { selectedColumns } = this.state
    onConfirm(selectedColumns)
    this.setState({ selectedColumns: columns })
  }
}

export default injectIntl(PrintModal)
