import React, { ReactNode } from 'react'
import { IDraft, DraftModal, IDraftModalProps, IRole, IWorker } from '../../models'
import { Modal, Title, Field, Input, Button, Select, Spinner } from '../shared'
import { isValidUserName } from '../../utils'

interface IProps extends IDraftModalProps<IWorker> {
  roles: IRole[]
}

// tslint:disable:jsx-no-lambda

export class DraftWorkerModal extends DraftModal<IWorker, IProps> {
  protected initialDraft: IDraft<IWorker> = {
    isValid: false,
    value: { username: '', firstName: '', lastName: '', newPassword: '', confirmNewPassword: '', role: null }
  }

  constructor(props) {
    super(props)
    this.state = { draft: this.initialDraft }
  }

  private get isEveryFieldValid(): boolean {
    const { username, firstName, lastName } = this.state.draft.value
    return isValidUserName(username)
      && !!firstName
      && !!lastName
  }

  private get spinner(): ReactNode {
    if (!this.props.isLoading) return null
    return <Spinner />
  }

  public render(): ReactNode {
    const { isHidden, roles, title, isLoading } = this.props
    const { username, firstName, lastName, newPassword, confirmNewPassword, role } = this.state.draft.value

    return (
      <Modal isHidden={ isHidden } className="create-material-modal">
        <Title label={ title } />
        <div className="fields">
          <Field label="phoneNumber">
            <Input
              placeholder="phoneNumberPlaceholder"
              value={ username }
              onChange={ x => { this.handleChange({ username: x }) } }
            />
          </Field>
          <Field label="firstName">
            <Input
              placeholder="firstName"
              value={ firstName }
              onChange={ x => { this.handleChange({ firstName: x }) } }
            />
          </Field>
          <Field label="lastName">
            <Input
              placeholder="lastName"
              value={ lastName }
              onChange={ x => { this.handleChange({ lastName: x }) } }
            />
          </Field>
          <Field label="password">
            <Input
              type="password"
              placeholder="password"
              value={ newPassword || '' }
              onChange={ x => { this.handleChange({ newPassword: x }) } }
            />
          </Field>
          <Field label="confirmPassword">
            <Input
              type="password"
              placeholder="confirmPassword"
              value={ confirmNewPassword || '' }
              onChange={ x => { this.handleChange({ confirmNewPassword: x }) } }
            />
          </Field>
          <Field label="role">
            <Select
              labelProp={ 'name' }
              selected={ roles.find(x => role && x.id === role.id) }
              options={ roles }
              onChange={ x => { this.handleChange({ role: x }) } }
              placeholder="selectRole"
            />
          </Field>
        </div>
        <div className="buttons">
          <Button label={ title } onClick={ this.handleSubmit } isDisabled={ !this.isEveryFieldValid || isLoading } />
          <Button label="cancel" onClick={ this.props.onCancel } isDisabled={ isLoading } />
        </div>
        { this.spinner }
      </Modal>
    )
  }
}
