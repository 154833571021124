import React, { ReactNode } from 'react'
import { DispatchProp, connect } from 'react-redux'
import { autobind } from 'core-decorators'
import { AnyAction } from 'redux'
import { ISessionState, IStoreState } from '../../store/states'
import { CustomerPage, ICustomer, CrudPage, ITableProperty, emptyPage } from '../../models'
import { CustomerService } from '../../services'
import { setCustomersAction } from '../../store/actions'
import { Menu } from '../../containers'
import {
  Button,
  Panel,
  Table,
  Field,
  Toggle,
  Pagination,
  TableActions,
  DraftCustomerModal,
  PageHeader,
  Input,
  DeleteConfirmationModal
} from '../../components'

interface IProps {
  session: ISessionState
  page: CustomerPage
}

type Props = IProps & DispatchProp<AnyAction>

// tslint:disable:jsx-no-lambda

class CustomersPage extends CrudPage<ICustomer, Props> {
  private tableProperties: ITableProperty[] = [
    { key: 'name', label: 'name' },
    { key: 'operation', label: 'operation', className: 'no-flex' }
  ]

  private get tableItems(): Array<{}> {
    const items = this.props.page.items
    return items && items.map(x => ({
      name: x.name,
      operation:
        <TableActions onDelete={ () => this.handleOpenDelete(x) } onEdit={ () => this.handleOpenEdit(x) } />
    }))
  }

  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  public componentWillUnmount(): void {
    super.componentWillUnmount()
    this.props.dispatch(setCustomersAction(emptyPage))
  }

  public render(): ReactNode {
    const {
      isCreating,
      isEditing,
      isSendingRequest,
      editingItem,
      areArchivedIncluded,
      filters,
      deletingItem
    } = this.state
    const { page, session } = this.props
    return (
      <section className="page fully-aligned customers">
        <Menu />
        <article className="content">
          <PageHeader session={ session } label="customers" />
          <div className="filters">
            <Button label="new" className="new-button" onClick={ this.handleOpenCreate } />
            <Input
              value={ filters.query.value }
              placeholder="search"
              isInvalid={ !!filters.query.value && !filters.query.isValid }
              onChange={ x => this.handleFilterChange({ query: { value: x, isValid: x.length >= 3 } }) }
            />
          </div>
          <Panel>
            <Table properties={ this.tableProperties } items={ this.tableItems } />
          </Panel>
          <footer>
            <Field label="includeArchieved" >
              <Toggle
                isActive={ areArchivedIncluded }
                onChange={ this.handleArchivedIncludedChange }
              />
            </Field>
            <Pagination
              onClick={ this.fetchItems }
              isHidden={ !page.pagination }
              pagination={ page.pagination }
            />
          </footer>
        </article>
        <DraftCustomerModal
            title={ isCreating ? 'create' : 'save' }
            isHidden={ !isCreating && !isEditing }
            onSubmit={ (isCreating && this.handleCreate) || (isEditing && this.handleEdit) }
            onCancel={ (isCreating && this.handleCancelCreate) || (isEditing && this.handleCancelEdit) }
            item={ editingItem }
            isLoading={ isSendingRequest }
          />
        <DeleteConfirmationModal
          isHidden={ !deletingItem }
          onCancel={ () => this.handleOpenDelete(null) }
          onConfirm={ this.handleDelete }
          isLoading={ isSendingRequest }
        />
      </section>
    )
  }

  @autobind
  protected async fetchItems(page = 1, areArchivedIncluded = false): Promise<void> {
    try {
      const query = this.state.filters.query
      const result = await CustomerService.getCustomers(page, areArchivedIncluded, query, 20)
      this.props.dispatch(setCustomersAction(result))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    }
  }

  @autobind
  protected async handleDelete(): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await CustomerService.deleteCustomer(this.state.deletingItem.id)
      this.setState({ deletingItem: null })
      await this.fetchItems()
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleCreate(customer: ICustomer): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await CustomerService.createCustomer(customer)
      await this.fetchItems()
      this.setState({ isCreating: false })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }

  @autobind
  protected async handleEdit(customer: ICustomer): Promise<void> {
    try {
      this.setState({ isSendingRequest: true })
      await CustomerService.updateCustomer(customer)
      await this.fetchItems()
      this.setState({ isEditing: false, editingItem: null })
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error)
    } finally {
      this.setState({ isSendingRequest: false })
    }
  }
}

const mapStateToProps = ({ session, customers }: IStoreState): IProps => ({
  session,
  page: customers
})

export default connect(mapStateToProps)(CustomersPage)
