import React, { ReactNode, PureComponent, createRef } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { autobind } from 'core-decorators'
import './multi-select.scss'
import { Icon } from './icon'

interface IProps extends WrappedComponentProps {
  labelProp: string
  icon: string
  selected: any[]
  options: any[]
  onChange: (selected: any[]) => void
  placeholder?: string
}

interface IState {
  isOpen: boolean
}

class MultiSelect extends PureComponent<IProps, IState> {
  private openRef = createRef<HTMLDivElement>()

  private get classNames(): string {
    const options = this.props.options
    const isOpen = this.state.isOpen
    const isDisabled = !options || !options.length
    return `multi-select ${ isOpen ? 'open' : '' } ${ isDisabled ? 'disabled' : '' }`
  }

  private get selectedLabel(): string {
    const { selected, placeholder, intl } = this.props
    if (selected.length === 1) return selected[0][this.props.labelProp]
    else if (selected.length > 1) return intl.formatMessage({ id: 'multipleValuesSelected' })
    return intl.formatMessage({ id: placeholder })
  }

  private get options(): ReactNode {
    const { options, selected, labelProp } = this.props
    return options.map((x, i) => {
      const isSelected = !!selected.find(y => y.id === x.id)
      return (
        <li
          className={ `option ${ isSelected ? 'selected' : '' }` }
          key={ i }
          onClick={ this.handleOptionClick.bind(this, x) }
        >
          <input
            className="checkbox"
            type="checkbox"
            checked={ isSelected }
            onChange={ this.handleCheckboxChange }
          />
          <label className="label">{ x[labelProp] }</label>
        </li>
      )
    })
  }

  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false }
    document.addEventListener('click', this.handleDocumentClick)
  }

  public render(): ReactNode {
    return (
      <article className={ this.classNames }>
        <div
          className="selected"
          ref={ this.openRef }
          onClick={ this.handleClick }
        >
          <Icon icon={ this.props.icon } />
          <span className="label">{ this.selectedLabel }</span>
          <span className="amount">{ this.props.selected.length }</span>
        </div>
        <ul className="options">{ this.options }</ul>
      </article>
    )
  }

  @autobind
  private handleClick(): void {
    const isOpen = this.state.isOpen
    this.setState({ isOpen: !isOpen })
  }

  @autobind
  private handleOptionClick(option: any): void {
    const { onChange, selected } = this.props
    !!selected.find(x => x.id === option.id)
     ? onChange(selected.filter(x => x.id !== option.id))
     : onChange([ ...selected, option ])
  }

  @autobind
  private handleDocumentClick(event: MouseEvent): void {
    const openRef = this.openRef.current
    if (this.state.isOpen && !!openRef && event.target !== openRef) {
      this.setState({ isOpen: false })
    }
  }

  private handleCheckboxChange(): void {
    // fake controlled React component
  }
}

export default injectIntl(MultiSelect)
