import React from 'react'
import * as Aviator from 'aviator'
import { configurePageRenderer } from '../utils'
import { SplashPage, NotFoundPage } from '../pages'
import { authenticationPages, authenticationRoutes, authenticationRouteNames } from './authentication'
import { managementRouteNames, managementPages, managementRoutes } from './management'
import { administrationRouteNames, administrationPages, administrationRoutes } from './administration'
import { reportsRouteNames, reportsPages, reportsRoutes } from './reports'

export const routeNames = {
  splash: '',
  // home: 'home',
  notFound: 'not-found',
  ...authenticationRouteNames,
  ...managementRouteNames,
  ...administrationRouteNames,
  ...reportsRouteNames
}

const pages = {
  [routeNames.splash]: configurePageRenderer(<SplashPage />),
  // [routeNames.home]: configurePageRenderer(<HomePage />),
  [routeNames.notFound]: configurePageRenderer(<NotFoundPage />),
  ...authenticationPages,
  ...managementPages,
  ...administrationPages,
  ...reportsPages,
}

const routes = {
  target: pages,
  [`/${ routeNames.splash }`]: routeNames.splash,
  // [`/${ routeNames.home }`]: routeNames.home,
  ...authenticationRoutes,
  ...managementRoutes,
  ...administrationRoutes,
  ...reportsRoutes,
  notFound: routeNames.notFound
}

export function initializeRouting(): void {
  Aviator.setRoutes(routes)
  Aviator.dispatch()
}
