import React, { PureComponent } from 'react'
import { autobind } from 'core-decorators'

interface IProps {
  icon: string
  onClick?: () => void
  className?: string
  isHidden?: boolean
}

export class Icon extends PureComponent<IProps> {
  public get src(): string {
    return `/assets/icons/${ this.props.icon }.svg`
  }

  private get className(): string {
    const className = this.props.className
    return `icon ${ className ? className : '' }`
  }

  public render() {
    if (this.props.isHidden) return null
    return <img className={ this.className } onClick={ this.handleClick } src={ this.src } alt="icon" />
  }

  @autobind
  private handleClick(): void {
    const onClick = this.props.onClick
    if (onClick) onClick()
  }
}
