import React, { PureComponent, ReactNode } from 'react'
import { Icon } from '../shared'
import './actions.scss'

interface IProps {
  onEdit?: () => void
  onDelete?: () => void
}

export class TableActions extends PureComponent<IProps> {
  private get separator(): ReactNode {
    const { onEdit, onDelete } = this.props
    if (!onEdit || !onDelete) return null
    return <figure className="separator" />
  }

  public render(): ReactNode {
    const { onDelete, onEdit } = this.props
    return (
      <div className="table-actions">
        <Icon icon="edit" onClick={ onEdit } isHidden={ !onEdit } />
        { this.separator }
        <Icon icon="delete" onClick={ onDelete } isHidden={ !onDelete } />
      </div>
    )
  }
}
